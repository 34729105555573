@mixin banner-slider($theme) {
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $font-size: map-get($map: $theme, $key: font-size);
 
  --swiper-theme-color: #FBBB1E;

  .slide-container {
    background-size: cover;
    height: 100%;
  }

  .banner {
    display: flex;
    position: relative;

    &.center {
      justify-content: center;
      text-align: center;
    }

    &.right {
      justify-content: flex-end;
      text-align: right;
    }

    .info {
      padding: 20px 50px; // adjust padding for mobile and tablet
      position: absolute;
      z-index: 1;

      &.bottom {
        align-self: flex-end;
      }

      &.center {
        align-self: center;
      }

      .text {
        font-size: $font-size + 3;
        color: $text-on-primary;
      }
    }
  }
}
