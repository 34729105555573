@mixin calendar-page($theme) {
  $is-dark: map-get($theme, is-dark);
  $radius: map-get($theme, radius);
  $text-on-primary: map-get($theme, text-on-primary);
  $text-color: map-get($theme, text-color);
  $font-size: map-get($theme, font-size);

  calendar-page{
    .background {
      background-image: url('/assets/images/calendar/BG.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: calc(100vh - 70px - 50px);
      position: sticky;
  
      @media (max-width: 1250px) {
        height: auto;
      }
  
      .calendar-col {
        cursor: pointer;
      }
    }
  
    .calendar-title {
      height: 170px;
      @media (max-width: 1700px) {
        height: 100px;
      }
    }
  
    .halloween-card {
      background-color: #333;
      border-radius: $radius + 4;
      color: $text-on-primary;
      height: 195px;
  
      .badge {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 4px !important;
        color: $text-on-primary;
        font-size: $font-size;
        height: 33px;
        position: absolute;
        top: 17px;
        left: 30px;
        width: 57px;
      }
  
      .text-start {
        line-height: 21px;
        text-align: start;
      }
    }
  
    .calendar-description {
      color: $text-on-primary;
    }
  }

  .calendar-dialog {
    .mat-dialog-container {
      casino-calendar-dialog {
        .mat-card{
          background-color: transparent;

          &-title{
            background: transparent;
            
            a{
              display: none;
            }
          }
        }
    
        .title{
          color: $text-color;
          background: transparent;
          font-size: $font-size + 3;
          font-weight: 400;
          text-transform: capitalize;
          
        }
    
        .text-muted{
          color: grey;
          font-size: $font-size - 1;
        }
    
        .button-container{
          @media #{$mat-lt-sm} {
            position: fixed;
            bottom: 0;
            padding: 10px;
          }
        }
      }

      @include tablet {
        width: 300px;
      }
    }
  }
}
