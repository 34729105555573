@mixin containers($theme) {
  .content {
    min-height: calc(100vh - 150px);
  }

  header.left + router-outlet + main {
    &.me-container,
    .home-casino-container .casino-wrapper .scrollable-col {
      .content {
        min-height: calc(100vh - 110px);
      }
    }
  }

  .container {
    &.expanded {
      max-width: 1920px - 60;
    }

    &.card-container {
      padding: 30px 15px;

      @media #{$mat-lt-sm} {
        max-width: 100%;
        padding: 0;
        width: 100%;
      }
    }

    @media #{$mat-lt-sm} {
      max-width: 100%;
    }
  }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;

    > .col,
    > [class*='col-'] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .no-gutters {
    margin-left: 0;
    margin-right: 0;
  }

  .no-gutters > .col,
  .no-gutters > [class*=col-] {
    padding-left: 0;
    padding-right: 0;
  }

  @include small-desktop {
    .header2 .content {
      min-height: calc(100vh - 160px);
    }
  }
}
