
@mixin generic($theme) {

  $primary-color: map-get($map: $theme, $key: primary);
  $font-size: map-get($map: $theme, $key: font-size);
  $text-color: map-get($map: $theme, $key: text-color);
  $background: map-get($map: $theme, $key: background);
  $tertiary-color: map-get($map: $theme, $key: tertiary);
  $item-active-color: map-get($map: $theme, $key: tertiary);
  font-size: $font-size;
  color: $text-color;
  background-color: $background;
  user-select: none;
  user-drag: none;
  font-family: 'Roboto', sans-serif;
  margin: 0;

  dl,
  dt,
  dd,
  ol,
  ul,
  li {
    margin: 0;
    padding: 0;
  }

  table {
    border-collapse: collapse; // Prevent double borders
  }

  fieldset {
    border: none;
    margin: 0;
    min-width: 0;
    padding: 0;
  }

  // ----------------------------------------------------------------
  // Basic Layout Styles
  // ----------------------------------------------------------------
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $tertiary-color;
    // font-family: $heading-font;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  &.is-mobile {
    .body {
      overflow-x: hidden;

      .content-container {
        margin-bottom: 80px;
      }
    }
  }

  // .content {
  //   min-height: 100vh;
  // }

  .max-vh {
    max-height: calc(100vh - 150px);
    overflow-y: auto;

    /*&::-webkit-scrollbar {
      background: transparent !important;
      width: 5px;
      z-index: 1;
    }*/

    @media #{$mat-lt-md} {
      max-height: calc(100vh - 100px);
    }
  }

  @media #{$mat-gt-sm} {
    .header2 .max-vh {
      max-height: calc(100vh - 160px);
    }
  }

  &.seamless {
    background-color: red;

    .max-vh {
      max-height: 100%;
    }
  }

  header.left + router-outlet + main,
  header.left + router-outlet + section {
    &:not(.live-container) {
      .max-vh,
      .home-casino-container .casino-wrapper .scrollable-col {
        max-height: calc(100vh - 110px);
      }
    }

    &.live-container {
      page-live-overview .max-vh {
        max-height: calc(100vh - 275px);
      }

      .live-event-container:not(.mobile) .max-vh {
        max-height: calc(100vh - 150px);
      }
    }
  }

  header.left + router-outlet + section,
  header.left + router-outlet + agent-login-page mat-tab-group {
    min-height: calc(100vh - 110px);
  }

  a {
    color: $item-active-color;
    cursor: pointer;
    text-decoration: none !important;

    img {
      border: none;
    }

    // &.active,
    // &:hover {
    //   color: $primary-color;
    // }
  }

  * {
    outline: none;
  }


  // Scrollbar
  .ps__rail-x,
  .ps__rail-y {
    background: transparent !important;
    width: 5px;
    z-index: 1;
  }

  &:not(.is-mobile) {

    .ps__thumb-y {
      width: 5px !important;
    }

    .ps__thumb-x {
      height: 5px !important;
    }

    .ps--active-y {
      > .ps__rail-y {
        margin-right: -2px;
      }

      > .ps-content {
        margin-right: 4px;
      }
    }
  }

  md-icon {

    &.spaced {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.statusdot {
  border-radius: 50%;
  float: right;
  height: 10px;
  margin-right: 3px;
  margin-top: 3px;
  width: 10px;

  &.won {
    background: #008000;
  }

  &.lost {
    background: #F00;
  }

  &.open {
    background: #FF0;
  }
}
