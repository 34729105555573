@mixin buttons($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $text-color: map-get($map: $theme, $key: text-color);
  $primary-color: map-get($map: $theme, $key: primary);
  $font-size: map-get($map: $theme, $key: font-size);
  $primary: map-get($map: $theme, $key: primary);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $secondary-color: map-get($map: $theme, $key: secondary);
  $text-on-secondary: map-get($map: $theme, $key: text-on-secondary);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $text-on-tertiary: map-get($map: $theme, $key: text-on-tertiary);
  $button-y-padding: 8px;
  $button-x-padding: 16px;
  $radius: map-get($map: $theme, $key: radius);
  $success: map-get($map: $theme, $key: success);
  $text-on-success: map-get($map: $theme, $key: text-on-success);
  $danger: map-get($map: $theme, $key: danger);
  $text-on-danger: map-get($map: $theme, $key: text-on-danger);
  $warning: map-get($map: $theme, $key: warning);
  $text-on-warning: map-get($map: $theme, $key: text-on-warning);
  $button-transition: all .2s ease-in-out;
  $register: map-get($map: $theme, $key: register);
  $text-on-register: map-get($map: $theme, $key: text-on-register);
  $bet-settings-btn-background: if($is-dark, lighten($background, 5%), #F5F5F5);
  $bet-settings-btn: if($is-dark, $text-on-primary, darken($background, 30%));
  $print-report-cashier-background: if($is-dark, darken($background, 5%), $text-on-primary);

  .back-btn {
    color: $text-color;
    font-size: $font-size;
    opacity: .9;
  }

  .mat-mdc-button-base {
    &:not(.mdc-fab):not(.mat-mini-fab) {
      --mdc-outlined-button-container-height: auto;
      --mdc-filled-button-container-height: auto;
      --mdc-protected-button-container-height: auto;
      border-radius: $radius !important;
      font-family: inherit;
      font-size: $font-size;
      font-weight: bold;
      line-height: 1;
      padding: $button-y-padding $button-x-padding;
      text-transform: uppercase;
      transition: $button-transition;
    }

    &.stroke-primary,
    &.mdc-button--outlined {
      border: none;

      &.mat-primary {
        background: transparent;
        border: none;
        box-shadow: inset 0 0 0 1px $primary-color;
        color: $primary-color;

        &:hover {
          background: $primary-color;
          color: $text-on-primary;
        }
      }

      &.stroke-primary {
        background: transparent;
        border: none;
        box-shadow: inset 0 0 0 1px $text-on-primary;
        color: $text-on-primary;

        &:hover {
          background: $text-on-primary;
          color: $primary-color;
        }
      }

      &.stroke-bg {
        background: transparent;
        border: none;
        box-shadow: inset 0 0 0 1px $text-color;
        color: $text-color;

        &:hover {
          background: $primary-color;
          box-shadow: inset 0 0 0 1px $primary-color;
          color: $text-on-primary;
        }
      }

      &.tertiary {
        background: transparent;
        border: none;
        box-shadow: inset 0 0 0 1px $tertiary;
        color: $tertiary;

        &:hover {
          background: $tertiary;
          color: $text-on-tertiary;
        }
      }

      &.warning {
        background: transparent;
        border: none;
        box-shadow: inset 0 0 0 1px $warning;
        color: $warning;

        &:hover {
          background: $warning;
          color: $text-on-warning;
        }
      }

      &.success {
        background: transparent;
        border: none;
        box-shadow: inset 0 0 0 1px $success;
        color: $success;

        &:hover {
          background: $success;
          color: $text-on-success;
        }
      }

      &.danger {
        background: transparent;
        border: none;
        box-shadow: inset 0 0 0 1px $danger;
        color: $danger;

        &:hover {
          background: $danger;
          color: $text-on-danger;
        }
      }

      &:disabled {
        cursor: not-allowed;
        opacity: .8;
      }
    }

    &.mdc-button--unelevated {
      &.register-btn {
        background: $register;
        color: $text-on-register !important;

        &:hover {
          background: $register;
          color: $text-on-register;
        }
      }

      &.claim-btn {
        background: $primary;
        color: $text-on-primary;

        &:hover {
          background: transparent;
          color: $primary;
        }
      }

      &.betslip-header-btn {
        background: $bet-settings-btn-background;
        color: $bet-settings-btn;
        opacity: .6;

        &.active {
          background: $background;
          color: $tertiary;
          opacity: 1;
        }
      }

      &.bonus-table-btn {
        font-size: $font-size - 3;
      }

      &.print-report-cashier {
        background-color: $print-report-cashier-background;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: .8;
      }
    }

    &.mdc-button--raised {
      &.search-btn {
        background: $secondary-color;
        color: $text-on-secondary;
      }
    }
  }
}
