@mixin read-more($theme) {
  $is-dark: map-get($theme, is-dark);
  $background: map-get($theme, background);
  $default-content-background: if($is-dark, lighten($background, 5%), lighten($background, 100%));

  read-more {
    .read-more {
      &-content {
        line-height: 25px;
        position: relative;
      }

      &-btn {
        justify-content: flex-start !important;
        margin-top: 6px;

        .mat-button-base {
          background: $default-content-background;
          padding: 6px 10px !important;
        }
      }
    }
  }
}
