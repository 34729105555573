@mixin stake($theme) {
  $border-color: map-get($theme, border-color);

  stake {
    display: block;

    .default-stake-input {
      border: 1px solid $border-color;
      border-radius: 30px;
      position: relative;

      .button-container {
        transform: scale(.75);
      }

      input {
        text-align: center;
      }

      .mat-form-field {
        &-infix {
          width: 100%;
          padding: 0;

          input{
            padding-block: 5px;
          }
        }

        &-outline {
          border: none;

          &-start,
          &-end,
          &-gap {
            border-width: 0 !important;
          }
        }

        &-subscript-wrapper {
          display: none;
        }
      }
    }
  }
}
