@mixin promotions-component($theme) {
  $primary-color: map-get($map: $theme, $key: primary);

  .promotions-container {
    .view-promo a {
      background: $primary-color;
    }

    mat-card {

      div {
        flex-direction: column;

        @media #{$mat-gt-xs} {
          flex-direction: unset;
        }
      }
    }
  }
}
