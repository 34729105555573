@mixin games-list($theme) {
  $font-size: map-get($map: $theme, $key: font-size);
  $tertiary: map-get($map: $theme, $key: tertiary);

  .games-list-container {
    padding: 0;

    .games-row {
      .col-game {
        flex: 0 0 50%;
        margin-bottom: 10px;
        max-width: 50%;

        game-tile {
          .overlay {
            .overlay-container {
              .favourites-icon {
                font-size: $font-size + 2;
              }
            }

            .play-container {
              .as-button {
                font-size: $font-size - 3;
                padding: 6px 12px;
              }
            }
          }
        }

        .game-info {
          font-size: $font-size - 2;
          opacity: .9;
          padding-top: 2px;
        }

        &:hover {
          .game-info {
            color: $tertiary;
            opacity: 1;
          }
        }
      }
    }
  }

}
