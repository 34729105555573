@mixin badge($theme) {
  $primary-color: map-get($map: $theme, $key: primary);
  $font-size: map-get($map: $theme, $key: font-size);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $success: map-get($map: $theme, $key: success);

  .badge {
    @include ellipsis;
    align-items: center;
    background: $primary-color;
    border-radius: 50%;
    color: $text-on-primary;
    display: flex;
    font-size: $font-size - 3;
    font-weight: bold;
    height: 20px;
    justify-content: center;
    margin-left: 8px;
    text-align: center;
    width: 20px;

    &.badge-cat {
      float: right;
    }

    &-new {
      background-color: $success;
      border-radius: 36px;
      font-size: $font-size;
      margin-left: 0;
      padding-inline: 8px;
      text-transform: uppercase;
      width: fit-content;
    }
  }
}
