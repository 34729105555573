@mixin mobile-prefix($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $text-on-primary: map-get($theme, text-on-primary);
  $radius: map-get($theme, radius);
  $border-color: if($is-dark, rgba(255, 255, 255, .7), rgba(0, 0, 0, .42));

  mobile-prefix {
    display: block;
    width: 100%;

    @include common-input($text-on-primary, $radius);

    .ng-touched.ng-invalid {
      input,
      select {
        border-color: #900;
      }
    }

    .mat-mdc-form-field {
      display: block;
      width: 100%;

      &-infix {
        border-top-width: .3em !important;
        padding: 6px .75em !important;
      }
    }

    .mobile-prefix-container {
      display: flex;
      max-width: 100%;
      position: relative;
    }

    .number {
      width: calc(100% - 125px);
    }

    .prefix-field {
      margin-right: 4px;

      .mat-form-field-appearance-outline .mat-form-field-flex:hover .mdc-notched-outline {
        border-color: $border-color !important;
        color: $border-color !important;
      }

      .mat-mdc-form-field {
        flex: 0 0 95px;
        max-width: 95px;

        &.mat-focused.mat-primary .mat-select-arrow {
          color: inherit;
        }
      }

      .mat-form-field-infix {
        align-items: center;
        display: flex;

        .flag {
          height: 12px;
          margin-right: 4px;
          min-width: 12px;
          position: absolute;
        }

        .mat-select-trigger {
          padding-left: 22px;
        }

        .mat-select-arrow-wrapper {
          color: $text-on-primary;
          transform: translateY(0);
        }
      }
    }
  }
}
