@mixin games-categories-menu($theme) {
  $background: map-get($map: $theme, $key: background);
  $secondary: map-get($map: $theme, $key: secondary);
  $categories-img-dir: '/assets/images/casino/categories';
  $is-dark: map-get($map: $theme, $key: is-dark);
  $menu-background: if($is-dark, lighten($background, 5%), darken($background, 5%));
  $text-color: map-get($map: $theme, $key: text-color);
  $radius: map-get($map: $theme, $key: radius);


  .categories-menu-container {
    background: $menu-background;
    font-size: 11px;

    &.mobile {
      ul {
        overflow-x: auto;

        li {
          margin: 10px;
        }
      }
    }

    &:not(.mobile) {
      ul {
        li {
          margin: 10px 15px;
        }
      }
    }

    ul {
      background: $menu-background;
      list-style: none;
      white-space: nowrap;

      li {
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
        position: relative;
        text-transform: uppercase;
        width: auto;

        .badge {
          position: absolute;
          right: 5px;
          top: 0;
        }

        &.active,
        &:hover {
          color: $secondary;

          .icon-category {
            background-color: $secondary;
          }
        }

        div {
          transition: all .2s;
        }
      }
    }

    .icon-category {
      border-radius: $radius;
      color: $text-color;
      font-size: 25px;
      height: auto;
      margin: 0 auto 10px;
      padding: 10px 5px;
      width: 60px;
    }
  }
}
