@mixin field($theme) {
  $background: map-get($map: $theme, $key: background);
  $is-dark: map-get($map: $theme, $key: is-dark);
  $danger: map-get($map: $theme, $key: danger);
  $file-button-background: if($is-dark, lighten(rgba($background, .79), 10%), rgba(#000, .04));
  $border-color: if($is-dark, #4E4E4E, $background);

  field {
    .file-container {
      border: 1px dashed $border-color;

      .buttonbar button {
        background-color: $file-button-background;
      }
    }

    .file-content {
      border-top: 1px dashed $border-color;
    }

    .file-error {
      color: $danger;
    }
  }
}
