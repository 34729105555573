@mixin page-register($theme) {
  $is-dark: map-get($theme, is-dark);
  $radius: map-get($theme, radius);
  $background: map-get($theme, background);
  $font-size: map-get($theme, font-size);
  $text-on-primary: map-get($theme, text-on-primary);
  $bg-register: if($is-dark, lighten($background, 5%), $text-on-primary);
  $success: map-get($theme, success);
  $height-container: 700px;

  .register-page {
    .registration-page-wrapper {
      .registration-container {
        max-width: 600px;
      }
    }

    mobile-prefix .mat-mdc-form-field {
      padding-bottom: 0;
    }

    .mat-mdc-form-field-subscript-wrapper {
      bottom: 12px;
    }

    @include small-desktop {
      .min-vh {
        height: calc(100vh - 150px);
      }
    }

    banner-slider {
      swiper-container {
        //height: 100%;

        swiper-slide,
        picture {
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }

      .slide-container {
        background-size: cover;
        height: 100%;

        @media (min-width: 619px) {
          @media screen and (max-height: 800px) {
            width: 80%;
          }

          @media screen and (max-height: 700px) {
            width: 70%;
          }

          @media screen and (max-height: 600px) {
            width: 55%;
          }
        }
      }
    }

    .register {
      .row {
        @include small-desktop {
          height: 100%;
        }

        @media #{$mat-lt-md-l} {
          &.register-success {
            height: calc(100svh - 56px);
          }
        }
      }

      @include mobile {
        field {
          &.ng-invalid .mat-mdc-form-field-error {
            display: block;
          }

          &.ng-valid .mat-mdc-form-field-error {
            display: none;
          }
        }
      }

      .register-success {
        .register-banner {
          display: none;
        }

        .new-card {
          height: 100%;
        }
      }

      &.has-banner {
        .register-success {
          .register-banner {
            display: none;
          }
        }

        @media (min-width: 1600px) {
          width: 840px;
        }
      }

      @include small-desktop {
        height: 100%;
        margin-inline: auto;
        width: 720px;
      }

      &-banner {
        @include small-desktop {
          height: 100%;
        }

        .banner {
          background-color: $background !important;

          @include mobile {
            justify-content: center;
          }
        }
      }

      .mat-stepper-horizontal {
        //background: $bg-register;
        height: 100%;
      }

      .mat-horizontal-content-container {
        height: 100%;
        padding: 10px 24px;

        @include small-desktop {
          max-height: calc($height-container - 168px);
        }

        .mat-stepper-horizontal {
          height: 100%;
          position: relative;
        }
      }

      .mat-horizontal-stepper-wrapper {
        height: 100%;
      }

      .ps-content {
        height: 100%;

        @include small-desktop {
          width: 100%;
        }
      }

      registration {
        .reg-buttons-wrapper {
          display: flex;

          @include small-desktop {
            //background-color: $bg-register;
            inset: auto 24px 20px;
            position: absolute;
            z-index: 1;
          }

          .mdc-button {
            width: 100%;
          }
        }

        .outcome-card,
        .success {
          height: 100%;
        }

        .mat-form-field {
          width: 100%;
        }

        .success {
          &-icon {
            color: $success;
            font-size: 120px;
          }

          .text-wrapper {
            line-height: 24px;
            margin-inline: auto;
            max-width: 450px;
          }

          .mat-card-actions {
            @include small-desktop {

            }
            inset: auto 20px 0;
            position: absolute;
          }
        }

        form {
          height: 100%;
        }

        .mat-horizontal-stepper-header-container {
          padding-top: 15px;
        }

        .mat-stepper-horizontal-line {
          top: 36px;
        }

        .mat-step-label-active {
          font-weight: 400 !important;
        }

        .mat-step-text-label {
          font-size: $font-size;
        }

        .mat-step-header .mat-step-icon {
          padding: 10px;

          md-icon {
            font-size: $font-size;
          }
        }
      }

      &-banner {
        banner-slider {
          height: 100%;

          .slider-container {
            height: 100%;

            .swiper-container {
              border-radius: $radius 0 0 $radius;
              height: 100%;
            }
          }
        }
      }
    }
  }

}
