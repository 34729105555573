@mixin material-settings($theme) {

  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $text-color: map-get($map: $theme, $key: text-color);
  $primary-color: map-get($map: $theme, $key: primary);
  $item-active-color: map-get($map: $theme, $key: active);
  $border-color: map-get($map: $theme, $key: border-color);
  $default-content-background: if($is-dark, lighten($background, 5%), lighten($background, 100%));
  $font-size: map-get($map: $theme, $key: font-size);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $icon: map-get($theme, icon);
  $item-active-background: if($is-dark, lighten($background, 3%), lighten($background, 3%));
  $radius: map-get($map: $theme, $key: radius);
  $title-background: if($is-dark, lighten($background, 10%), lighten($background, 100%));
  $shadow-small: if($is-dark, 0 2px 2px 1px rgba(#000, .45), 0 2px 2px 1px rgba(#000, .2));
  $table-header-bg: if($is-dark, lighten($background, 10%), lighten($background, 100%));
  $table-bg: if($is-dark, lighten($background, 8%), lighten($background, 100%));
  $cell-padding: 5px 10px;
  $white-transparency: rgba(255, 255, 255, .2);
  $input-border-color: if($is-dark, $white-transparency, #333);
  $border-color: if($is-dark, rgba(255, 255, 255, .7), rgba(0, 0, 0, .42));

  .mat-mdc-form-field-hide-placeholder .mat-mdc-select-placeholder {
    @include ellipsis;
  }

  .mat-flat-button {
    md-icon {
      span {
        display: flex;
      }
    }
  }

  .ps {
    .ps__rail-x {
      height: 3px;

      .ps__thumb-x {
        height: 3px;
      }
    }
  }

  .mat-button,
  .mat-flat-button,
  .mat-icon-button,
  .mat-stroked-button {
    min-width: unset;
  }

  hr {
    border-color: $border-color;
    width: 100%;

    &.light {
      border-color: $text-color;
    }
  }

  .mat-button-toggle-group,
  .mat-button-toggle-standalone {
    border-radius: $radius;
    box-shadow: $shadow-small;
  }

  .mat-button-toggle {
    background-color: $title-background;
    color: $text-color;
  }

  .mat-button-toggle-checked {
    background-color: $primary-color;
    color: $text-on-primary;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 1;
    padding: 10px;
  }

  .mat-paginator-range-actions .mat-button-base {
    padding: 0 !important;
  }

  .mat-card:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  .mat-mdc-dialog-container {
    border-radius: $radius !important;
  }

  .mat-menu-item {
    font-size: $font-size;
  }

  .mat-mdc-select-panel-wrap .mat-mdc-select-panel {
    min-width: unset !important;
  }

  .mat-mdc-checkbox-layout .mat-mdc-checkbox-label {
    line-height: 1.5;
  }

  .mat-mdc-slide-toggle .mat-mdc-slide-toggle-ripple {
    top: calc(50% - 40px);
  }

  .mat-button-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .mat-mdc-progress-bar-background {
    @if $is-dark {
      fill: transparent;
    }
  }

  .mat-mdc-progress-spinner circle,
  .mat-mdc-spinner circle {
    stroke: $tertiary;
  }

  .mat-mdc-tab-label {
    height: 40px;
  }

  .mat-mdc-tab-group.mat-primary .mat-ink-bar,
  .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $item-active-color;
  }

  .mat-mdc-tab-group.hide-tabs {
    .mat-mdc-tab-header {
      display: none;
    }
  }

  .fancy-mat-group {
    .mat-tab {
      &-header {
        background-color: $item-active-background;
        border-bottom: 0 !important;
      }
    }

    @media #{$mat-lt-md-l} {
      .mat-tab {
        &-header {
          background-color: transparent;

          .mat-ink-bar {
            display: none;
          }
        }

        &-labels {
          flex-direction: column;
        }

        &-label {
          border-bottom: 1px solid $border-color;
          flex-basis: auto !important;
          justify-content: start;

          &-active {
            background-color: transparent !important;
            border-bottom: 1px solid $primary-color;
          }
        }
      }
    }
  }

  .mat-mdc-checkbox-checked.mat-accent {
    .mat-checkbox-background {
      background-color: $item-active-color;
    }
  }

  .mat-primary {
    .mat-mdc-option.mat-mdc-selected:not(.mat-option-disabled) {
      color: $item-active-color;
    }
  }

  .mat-mdc-form-field.mat-focused {
    .mat-mdc-form-field-outline {
      color: $border-color;
    }

    .mat-mdc-form-field-label {
      color: $item-active-color;
    }
  }

  .mat-mdc-tab,
  .mat-tab-link {
    font-size: $font-size;
  }

  .mat-drawer-container {
    background: transparent;
  }

  .mat-mdc-card,
  .mat-mdc-dialog-container,
  .mat-menu-panel {
    background: $default-content-background;
  }

  .mat-mdc-select-arrow-wrapper {
    height: auto;
  }

  .mdc-text-field--filled.mdc-text-field--disabled {
    background-color: transparent;
  }

  .mat-tab-nav-bar {
    border-bottom: 1px solid $border-color;
  }

  .mat-table,
  .mat-mdc-table,
  .mat-paginator {
    background: transparent;
  }

  .mat-header-cell,
  .mat-cell {
    padding: 5px;
    white-space: nowrap;
  }

  .mat-mdc-tab-labels {
    font-weight: normal;
    min-width: 1px !important;
    text-transform: capitalize;

    .mdc-tab {
      height: 40px;
      //flex: 1 1 auto;
      min-width: 1px !important;
      padding: 0 12px !important;

      &__text-label {
        opacity: .6;
      }
    }

    &:not(.mdc-tab--active) {
      .mdc-tab__text-label {
        color: $text-color;
      }
    }

    .mdc-tab-indicator__content--underline, {
      border-color: $item-active-color;
    }

    .mdc-tab--active,
    .mdc-tab--active .mdc-tab__ripple::before, .mat-mdc-tab.mdc-tab--active .mat-ripple-element {
      background-color: $item-active-background;
    }

    .mdc-tab--active .mdc-tab__text-label,
    .mdc-tab--active:hover .mdc-tab__text-label {
      color: $item-active-color !important;
      font-weight: bold;
      opacity: 1 !important;
    }
  }

  .mat-mdc-card {
    border-radius: $radius;
    margin: 5px;
    overflow: hidden;
    padding: 0 !important;
  }


  &.is-mobile {
    .mat-mdc-dialog-container {
      border-radius: 0;
    }

    .mat-mdc-select-panel {
      min-width: 100% !important;
    }
  }

  //.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined),
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    .mat-mdc-form-field-infix {
      padding-bottom: 0.8em;
      padding-top: 0.8em;
    }
  }

  .mat-mdc-form-field {
    position: relative;
    width: 100%;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;
  }

  .mat-form-field-appearance-fill,
  .mat-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      border-bottom: .1em solid transparent;
      border-top: .4em solid transparent;
      min-height: auto;
    }
  }

  .mat-mdc-form-field-label-wrapper {
    padding-top: .4em;
    top: -.4em;
  }

  .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    margin: 0;
    padding-bottom: 0;
  }

  .mat-mdc-form-field-prefix {
    margin-right: 5px;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 16px;

    &.mdc-floating-label--float-above {
      top: 10px;
    }
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding: 0.8em 0;
  }

  .mat-mdc-form-field-icon-suffix {
    .mat-icon-button {
      background-color: transparent;
      color: var(--mat-select-enabled-arrow-color);
      height: auto;
      padding: 8px !important;
      width: auto;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      height: auto;
      padding: 0;
      width: auto
    }
  }

  .mat-mdc-form-field-focus-overlay,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mat-mdc-table,
  .mat-table {
    background: $table-bg;
    width: 100%;

    .mat-mdc-row,
    .mat-row,
    .mat-mdc-header-row {
      height: 35px;

      .mat-header-cell,
      .mat-mdc-header-cell {
        background: $table-header-bg;
        border-bottom: 2px solid $tertiary;
        color: $tertiary;
        font-size: $font-size;
        padding: $cell-padding;
        text-align: left;
      }
    }

    .mat-mdc-row {
      .mat-mdc-cell {
        border-bottom-color: $border-color;
        font-size: $font-size;
        padding: $cell-padding;
        text-align: left;

        .mdc-button {
          min-width: auto;
        }
      }

      &:last-child {
        .mat-cell {
          border-bottom: none;
        }
      }
    }
  }

  .mat-flat-button.mat-primary {
    color: rgba($text-on-primary, .85);
    font-weight: bold;

    &:hover {
      color: $text-on-primary;
    }
  }

  .mat-mdc-progress-bar-fill::after {
    background-color: $tertiary;
  }

  .mat-mdc-form-field-subscript-wrapper {
    bottom: -4px;
    font-size: 10px;
    position: absolute;
    pointer-events: none;
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: auto;
  }

  .mat-menu-item {
    height: 32px;
    line-height: 32px;
  }

  .mat-mdc-radio-button .mdc-radio {
    padding: 0;

    &__native-control {
      height: 20px;
      width: 20px;
    }
  }

  div.mat-mdc-select-panel {
    padding: 0;
    //margin-top: 25px;

    /*@include tablet {
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #AAA;
        border: none;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #999;
      }
    }*/

    .max-dropdown-h {
      max-height: 255px;

      .ps-content {
        margin-right: 0;
      }
    }

    .mat-mdc-optgroup-label,
    .mat-mdc-option {
      min-height: 2em;

      > .mdc-list-item__primary-text {
        font-size: $font-size;
      }
    }
  }

  .mdc-checkbox {
    padding: 0;

    .mdc-checkbox__background {
      left: 0;
      top: 0;
    }
  }

  .mat-mdc-checkbox-touch-target,
  .mdc-checkbox .mdc-checkbox__native-control {
    height: auto;
    width: auto;
  }

  .mat-mdc-datepicker-toggle {
    button.mat-icon-button.mat-button-base {
      padding: 0;
    }


    .mat-button-wrapper {
      display: block;

      svg.mat-datepicker-toggle-default-icon {
        height: 20px;
        width: 20px;
      }
    }
  }

  .mat-focus-indicator.mat-datepicker-close-button,
  .mat-calendar-body-cell-content.mat-focus-indicator {
    position: absolute;
  }

  .mat-stepper-horizontal,
  .mat-stepper-vertical {
    background-color: transparent;
  }

  input:-webkit-autofill,
  .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    // .mat-form-field-autofill-control {
    box-shadow: 0 0 0 1000px $default-content-background inset;
    caret-color: $text-color;
    color: $input-border-color;
    -webkit-text-fill-color: $text-color;
  }

  header .mobile-prefix-container .mat-mdc-text-field-wrapper {
    border-color: $input-border-color !important;
  }


  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-ripple {
    background-color: #11959A;
  }

  header .mobile-prefix-container .mat-mdc-text-field-wrapper:hover {
    @if $is-dark {
      border-color: $text-on-primary !important;
    }
  }

  .mat-mdc-form-field-flex:hover {
    @if $is-dark {
      .mat-form-field-outline {
        color: $text-on-primary;
      }
    }
  }

  .mat-mdc-form-field.dropdown-search-filter {
    background: $title-background;
    overflow-x: hidden;
    padding: 0 10px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .mat-mdc-radio-group {
    .mat-mdc-radio-button {
      margin-right: 10px;
    }
  }

  mobile-prefix.mat-form-field-appearance-fill .mat-mdc-form-field-flex {
    background-color: inherit;
  }

  .mat-form-field-appearance-outline .mat-mdc-form-field-flex:hover .mdc-notched-outline {
    border-color: $border-color;
    color: $border-color;
  }

  .mat-focused {
    .mdc-notched-outline {
      border-color: $border-color;
      color: $border-color;
    }
  }
  @if $is-dark {
    .mat-mdc-input-element {
      caret-color: $text-on-primary;
    }
  }

  .new-dropdown {
    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
      padding: 0;
    }

    .mat-mdc {
      &-select-arrow-wrapper {
        height: auto;
      }

      &-form-field-infix {
        max-width: 180px;
      }
    }

    .mdc-line-ripple {
      display: none;
    }
  }

  .mat-mdc-paginator-outer-container {
    .mat-mdc-paginator-container {

      .mat-mdc-paginator-page-size {
        align-items: center;

        &-select {
          width: 64px;
        }

        .mdc-text-field--outlined {
          --mdc-outlined-text-field-outline-width: 0;
        }

        .mat-mdc-text-field-wrapper {
          box-shadow: none;
          border-bottom: 1px solid $border-color;
          height: auto;
        }
      }

      .mat-mdc-paginator-range-label {
        margin-inline: 20px;
      }
    }
  }

  .mat-mdc-slide-toggle {
    &.mat-mdc-slide-toggle {
      --mat-slider-hover-state-layer-color: #{$tertiary};
      --mat-slider-focus-state-layer-color: #{$tertiary};
      --mdc-switch-selected-hover-track-color: #{rgba($tertiary, .55)};

      .mdc-switch:enabled .mdc-switch__track::after {
        background: rgba($tertiary, .55);
      }

      .mdc-switch .mdc-switch__icon {
        &--on,
        &--off {
          display: none;
        }
      }
    }

    .mat-slide-toggle-ripple {
      top: calc(50% - 40px);
    }
  }

}

body:not(.light) .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  --mdc-linear-progress-track-color: rgba(255, 255, 255, .1);
}
