@mixin countdown($theme) {
  $background: map-get($map: $theme, $key: background);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $success: map-get($theme, success);
  $font-size: map-get($theme, font-size);
  $tertiary: map-get($map: $theme, $key: tertiary);

  countdown {
    &.big {
      .cell {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;

        &:not(:last-child) {
          margin-right: 6px;
        }

        .value {
          color: $tertiary;
          font-size: $font-size + 7;
          position: relative;
          text-align: center;
          z-index: 2;

          > div {
            display: flex;
            position: relative;

            .cypher {
              align-items: center;
              background-color: $background;
              display: flex;
              flex: 1;
              height: 40px;
              justify-content: center;
              margin-right: 2px;
              width: 30px;
            }
          }
        }

        .key {
          color: $text-on-primary;
          font-size: $font-size - 1;
          margin-top: 8px;
        }
      }

      .text-center {
        color: $text-on-primary;
        font-size: $font-size - 1;
        font-weight: normal;
        text-transform: capitalize;
      }
    }

    &.inline {
      display: flex;

      h4 {
        display: none;
      }

      .cell {
        color: $success;
        display: flex;
        font-weight: bold;

        .value {
          div {
            display: flex;
          }
        }

        .key {
          margin-right: 4px;
        }
      }
    }
  }
}
