@mixin registration-dialog($theme) {

  .registration-wrapper {
    padding: 10px;

    .mat-checkbox,
    .mat-stroked-button,
    .mat-form-field-type-mat-input,
    .mat-form-field-type-mat-select {
      width: 100%;
    }

    .mat-checkbox {
      .mat-checkbox-label {
        white-space: normal;
      }

      .mat-checkbox-inner-container {
        margin: 2px 8px auto 0;
      }
    }

    .section-agreement {
      ::ng-deep span {
        overflow-wrap: break-word;
        white-space: normal !important;
        word-break: break-word;
      }
    }

    .alert {
      padding: 6px 10px;
    }

    @media #{$mat-lt-sm} {
      .scrollbar-register {
        max-height: calc(100vh - 150px);
      }
    }
  }

  @include mobile {
    .currency-registration {
      max-height: 160px;
    }
  }
}
