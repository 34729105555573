@mixin double-range-slider($theme) {
  $primary: map-get($theme, primary);
  $radius: map-get($theme, radius);
  $text-on-primary: map-get($theme, text-on-primary);

  .range {
    .range-input {
      align-items: center;
      display: flex;
      height: 100%;
      min-height: 60px;
      position: relative;
      width: 100%;

      .range-control {
        appearance: none;
        width: 100%;
        outline: none;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        background-color: transparent;
        pointer-events: none;

        &::-webkit-slider-runnable-track,
        &::-moz-range-track,
        &::-ms-track {
          height: 5px;
        }

        &::-webkit-slider-runnable-track {
          -webkit-appearance: none;
        }

        &::-moz-range-track {
          -moz-appearance: none;
        }

        &::-ms-track {
          appearance: none;
        }

        &::-webkit-slider-thumb {
          appearance: none;
          @include range-slider-thumb-webkit($text-on-primary, $primary);
        }

        &::-moz-range-thumb {
          -webkit-appearance: none;
          @include range-slider-thumb-webkit($text-on-primary, $primary);
        }

        &::-ms-thumb {
          appearance: none;
          @include range-slider-thumb-webkit($text-on-primary, $primary);
        }

        &:active::-webkit-slider-thumb {
          background-color: $text-on-primary;
          border: 3px solid $primary;
        }
      }
    }

    .range-track {
      background-color: rgba(0, 0, 0, .05);
      border-radius: $radius;
      height: 5px;
      position: relative;
      margin: auto;
      top: 0;
      bottom: 0;
      width: 100%;

      &-fill {
        background-color: $primary;
        height: 100%;
        position: absolute;
      }

      &-value {
        :where(&-min, &-max) {
          opacity: .7;
          position: absolute;
          top: -24px;
        }
      }
    }

    .range-value {
      background-color: $primary;
      border-radius: $radius;
      color: $text-on-primary;
      padding: 4px 6px;
      position: absolute;
      top: 0;
      transform: translate(-50%, -50%);
      white-space: nowrap;

      &::after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $primary;
        bottom: -8px;
        content: '';
        height: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        width: 0;
      }
    }
  }
}

@mixin range-slider-thumb-webkit($background: #FFF, $border) {
  background-color: $background;
  border-radius: 50%;
  border: 2px solid $border;
  height: 16px;
  pointer-events: auto;
  width: 16px;
}
