@mixin view-selector($theme) {
  $selector-active-color: map-get($map: $theme, $key: primary);
  $selector-active-background-color: map-get($map: $theme, $key: background);
  $selector-color: map-get($map: $theme, $key: text-color);

  .view-selector {
    display: inline-block;
    list-style: none;
    margin-right: 12px;

    li {
      border-radius: 2px;
      cursor: pointer;
      float: left;
      padding: 10px;

      .rect {
        background: $selector-color;
        border-radius: 2px;
        display: block;
        margin: 0 2px;
        padding: 5px;
      }

      &.active {
        background: $selector-active-background-color;
        cursor: default;

        .rect {
          background: $selector-active-color;
        }
      }

      &.disabled {
        cursor: default;
        opacity: .4;
      }
    }
  }

}
