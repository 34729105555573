@mixin box($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $header-color: $text-on-primary;
  $font-size: map-get($map: $theme, $key: font-size);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $radius: map-get($map: $theme, $key: radius);
  $header-background: if($is-dark, lighten($background, 10%), unset);

  .box-header {
    background: $header-background;
    border-bottom: 2px solid $tertiary;
    height: 40px;

    &:not(.dialog) {
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
    }

    &.dialog {

      .box-title {
        width: 100%;

        .close-icon {
          color: $header-color;
          float: right;
          font-size: 20px;
          margin-right: -15px;
        }
      }
    }

    .box-title {
      color: $header-color;
      text-transform: uppercase;

      &.xl {
        font-size: $font-size + 2;
        padding: 15px 15px 15px 0;

        .icon::before {
          font-size: $font-size + 1;
        }

        .icons-right {
          right: 15px;
          top: 15px;
        }
      }

      &:not(.xl) {
        align-items: center;
        color: $tertiary;
        display: flex;
        font-size: $font-size;
        font-weight: bold;
        justify-content: space-between;
        padding: 10px 10px 10px 0;

        .icon::before {
          font-size: $font-size + 2px;
        }

        .icons-right {
          right: 10px;
          top: 10px;
        }
      }


      md-icon,
      .icon {
        font-size: $font-size + 2px;
        margin-bottom: -1px;
        margin-right: 7px;
      }


      .icons-right {
        position: absolute;
      }
    }
  }
}
