@mixin search-events($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $primary-color: map-get($map: $theme, $key: primary);
  $background: map-get($map: $theme, $key: background);
  $text-color: map-get($map: $theme, $key: text-color);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $text-color: map-get($theme, text-color);
  $list-bg: if($is-dark, lighten($background, 10%), lighten($background, 100%));
  $border-color: map-get($map: $theme, $key: border-color);
  $font-size: map-get($map: $theme, $key: font-size);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $radius: map-get($theme, radius);
  $highlight-background: if($is-dark, $text-on-primary, $text-color);

  .search-events-container {
    background: $list-bg;
    margin-top: 3px;

    .mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        border-top-width: .3em;
        padding: 5px .75em;
      }
    }

   @include common-input($text-color, $radius);

    .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
      padding-right: .75em;
    }

    .mat-form-field-outline {
      top: 2px;
    }

    .loader {
      position: absolute;
      right: 0;
      top: 5px;
    }

    .results-container {
      background: $list-bg;
      border: 2px solid rgba($border-color, .3);
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top: none;
      height: 80vh;
      left: -8px;
      margin-top: 4px;
      padding-top: 4px;
      position: relative;
      top: 5px;
      width: calc(100% + 28px);
      z-index: 2;

      ul {
        list-style: none;

        li {
          border-bottom: 1px solid $border-color;
          cursor: pointer;
          padding: 10px;

          &:hover {
            background: darken($background, 5%);
          }

          &:last-child {
            border-bottom: none;
          }

          .icon-container {
            padding-left: 5px;
            padding-right: 15px;
          }

          .match-row {
            .icon-container {
              color: $tertiary;
            }
          }

          .datetime {
            opacity: .75;
          }
        }
      }
    }
  }
}
