@mixin header($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($theme, background);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $header: map-get($map: $theme, $key: header);
  $primary-color: map-get($map: $theme, $key: primary);
  $first-row-background: $primary-color;
  $first-row-color: $text-on-primary;
  $second-row-background: map-get($map: $theme, $key: header);
  $text-on-header: map-get($map: $theme, $key: text-on-header);
  $third-row-background: if($is-dark, lighten($second-row-background, 15%), #F9F9F9);
  $new-menu-bg: if($is-dark, lighten($second-row-background, 6%), $header);
  $new-menu-bg-2: if($is-dark, darken($second-row-background, 10%), $header);
  $first-row-height: 40;
  $second-row-height: 70;
  $third-row-height: 40;
  $secondary: map-get($theme, secondary);
  $radius: map-get($map: $theme, $key: radius);
  $text-color: map-get($map: $theme, $key: text-color);
  $switcher-color: map-get($map: $theme, $key: tertiary);
  $font-size: map-get($map: $theme, $key: font-size);
  $border-topbar: darken($primary-color, 3%);
  $white-transparency: rgba(255, 255, 255, .2);
  $header-background: if($is-dark, lighten($background, 10%), unset);
  $footer-bg: map-get($theme, footer);
  $icon-size: 18px;
  $border-color: if($is-dark, rgba(255, 255, 255, .7), rgba(0, 0, 0, .42));

  .header-container {
    overflow: hidden;

    .actions-container {
      input:-webkit-autofill,
      .mat-mdc-text-field-wrapper {
        box-shadow: 0 0 0 1000px $primary-color inset;
        caret-color: $text-on-primary;
        color: $white-transparency;
        -webkit-text-fill-color: $text-on-primary;
      }
    }

    //input:-webkit-autofill,
    //.mat-form-field-appearance-outline .mat-form-field-outline,
    //.mat-form-field-autofill-control,
    //mobile-prefix input {
    //
    //}

    .mat-focused {
      .mat-form-field-outline {
        color: $border-color;
      }
    }

    .prefix-field .mat-select-value-text,
    .prefix-field .mat-select-arrow {
      color: $text-on-primary;
    }

    mobile-prefix.outline .mat-form-field-wrapper:not(:hover) {
      border-color: $white-transparency !important;
    }

    .logged {
      .actions-container {
        list-style: none;

        li {
          align-items: center;
          display: flex;
          height: 100%;
          padding: 0 5px;

          &.logged-actions {
            padding: 0 15px;

            &:last-child {
              border-right: none;
            }
          }

          a:not(.register-btn) {
            color: $text-on-primary;
          }

          .username {
            font-weight: bold;
          }
        }
      }
    }

    .row,
    .form-row {
      width: 100%;

      &.first {
        background: $first-row-background;
        color: $first-row-color;
        height: #{$first-row-height}px;

        .mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
          padding-inline: .75em;
          border-top-width: .3em;
        }

        @include common-input($text-on-primary, $radius);

        .actions-container {
          display: flex;
          flex-wrap: nowrap;
          list-style: none;

          li {
            align-items: center;
            display: flex;
            height: 100%;
            padding: 0 5px;

            &.logged-actions {
              border-right: 1px solid $border-topbar;
              padding: 0 15px;

              &:last-child {
                border-right: none;
              }

              .mdc-button {
                color: $text-on-primary;
                min-width: auto;
              }
            }

            a:not(.register-btn) {
              color: $text-on-primary;
            }

            .mat-button-ripple {
              display: none;
            }

            .lang-container {
              .flag {
                width: 30px;
              }
            }

            .username {
              font-weight: bold;
            }

            .mat-form-field-appearance-outline .mat-form-field-prefix {
              top: 0;
            }

            .forgot-password-btn {
              height: auto;
              width: auto;
            }
          }
        }

        .links {
          padding: 10px;
        }

        md-icon {
          font-size: $font-size;
        }
      }

      &.second {
        background: $second-row-background;
        color: $text-on-header;
        height: #{$second-row-height}px;

        .logo-container {
          align-items: center;
          display: flex;
          height: 70px;
          width: 70px;
          padding-left: 10px;

          .bck {
            background-position: left;
            background-repeat: no-repeat;
            height: 40px;
            width: 100%;
          }
        }

      }

      &.third {
        background: $third-row-background;
        color: $text-on-header;
        height: #{$third-row-height}px;

        .submenu-container {

          a {
            height: 40px;

            &.active {
              color: $text-on-header;
            }
          }
        }

      }

      &.left-layout {
        display: grid;
        grid-template-columns: 240px 1fr 360px;

        .logo-container {
          width: 100%;
        }
      }

    }

    .settings-container {
      ul {
        list-style: none;
      }

      li {
        display: inline;
        margin-right: 10px;

        .mat-mdc-form-field {

          .mat-mdc-text-field-wrapper {
            padding-bottom: 0;

            .mdc-floating-label--float-above {
              top: 14px;
            }
          }

          .mat-mdc-form-field-infix {
            width: 135px;
          }

          .mdc-line-ripple {
            display: none;
          }
        }
      }
    }

    menu {
      // this is the secondary menu ruleset
      &.submenu-container {
        ul {

          .lev-1 {
            display: none;
          }

          li {
            a {
              align-items: center;
              color: rgba($text-color, .5);
              display: flex;
              font-size: $font-size;
              font-weight: 700;
              padding: 0 10px;
              position: relative;
              text-transform: capitalize;

              &:hover:not(.active) {
                color: $text-color;
              }
            }

            md-icon {
              font-size: $font-size + 2px;
            }
          }
        }
      }
    }
  }

  .header-new {
    height: 70px;
    padding: 10px 0;

    .logged-area {
      .logged-actions {
        padding: 0;

        &:not(:last-child) {
          margin-right: 5px;
        }

        .user-info {
          background-color: $header-background;
          border-radius: $radius 0 0 $radius;
          padding: 10px 16px;
        }

        .deposit {
          border-radius: 0 $radius $radius 0 !important;
        }

        .menu-account {
          font-size: 18px;
          padding: 8px 12px;
        }
      }
    }

    &-menu {
      background-color: $primary-color;
      height: 50px;

      .new-menu {
        height: 100%;
        @include new-header-menu($secondary);
      }

      dropdown-timezones {
        margin: 0 1px;
      }

      .mat-form-field-appearance-legacy {
        .mat-form-field-underline {
          display: none;
        }

        .mat-form-field-wrapper {
          height: 100%;
          padding-bottom: 0;
        }

        .mat-form-field-flex {
          height: 100%;
        }
      }

      .mat-form-field-infix {
        align-items: center;
        display: flex;
        height: 100%;
        width: 70px;
      }

      .mat-form-field {
        background-color: $secondary;
        padding: 0 10px 0 15px;
      }

      &-3 {
        background-color: $new-menu-bg;
        height: 80px;

        // placeholder width
        .left-side {
          width: 152px;
        }

        .new-menu {
          @include new-header-menu($third-row-background);

          ul {
            justify-content: center;

            .nav-item {
              .nav-link {
                border-bottom: 3px solid transparent;

                &.active {
                  border-bottom: 3px solid $switcher-color;
                  color: $switcher-color;
                }

                span {
                  font-size: 12px;
                  font-weight: 700;

                  &::before {
                    display: block;
                    font-size: 30px;
                    font-weight: 400;
                    height: 30px;
                    margin: 0 auto 8px;
                    width: 30px;

                    @include mobile {
                      display: none;
                    }
                  }


                }
              }
            }
          }
        }

        &-submenu {
          background: $footer-bg;

          ul {
            display: flex;
            height: 40px;
            justify-content: center;
            width: 100%;

            div {
              display: flex;
              height: 100%;

              .nav-link {
                opacity: .85;

                &:hover {
                  opacity: 1;
                }

                md-icon {
                  display: none;
                }

                &.active .item-label {
                  color: $switcher-color;
                }
              }

              .item-label {
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }

      &-2 {
        background-color: $footer-bg;
        height: 50px;

        .new-menu {
          height: 100%;

          ul,
          .nav-item,
          .nav-item div,
          .nav-link {
            height: 100%;
          }

          .nav-link {
            border-radius: $radius $radius 0 0;
            clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
            font-weight: bold;
            padding: 0 20px;

            &.active {
              background-color: $new-menu-bg-2;
              color: $switcher-color;
            }
          }
        }
      }

      &-sports {
        background-color: $new-menu-bg-2;
        height: 40px;

        .ps-content {
          height: 100%;
        }

        .navbar {
          list-style: none;

          .nav-item {


            &:not(:last-child) {
              margin-right: 20px;
            }

            .nav-link {
              border-bottom: 2px solid transparent;
              color: $text-on-primary;
              opacity: .6;
              transition: .2s ease;
              white-space: nowrap;

              &.active {
                border-color: $switcher-color;
                color: $switcher-color;
                opacity: 1;
              }
            }

            &:hover {
              .nav-link {
                opacity: 1;
              }
            }
          }
        }

        .section-menu {
          ul,
          .nav-item,
          .w-100,
          .nav-link {
            height: 100%;
          }

          .nav-link {
            padding-inline: 0;

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }

    &-3 {
      background-color: $footer-bg;

      .logged-area {
        .logged-actions {
          .user-info {
            background-color: transparent;
            border-radius: 0;
            height: 32px;
            padding: 0 16px;

            &.active {
              color: $switcher-color;
            }
          }

          .username {
            color: $text-on-primary;
            font-weight: bold;
            padding: 0 10px;
          }
        }
      }

      .logged-actions {
        .menu-account {
          font-size: 18px;
          padding: 0;
        }
      }
    }

    &-2 {
      background-color: $footer-bg;

      .logged-area {
        .logged-actions {
          .user-info {
            background-color: transparent;
          }

          .menu-account {
            background-color: transparent;
          }

          .mat-menu-trigger {
            background-color: darken($footer-bg, 1%);
            border-radius: $radius;
            color: $text-on-primary;
            margin-left: 6px;
            padding: 0 10px;
          }
        }
      }
    }

    .bck {
      background-position: left;
      background-repeat: no-repeat;
      height: 40px;
      width: 100%;
    }

    .actions-container {
      list-style: none;

      li {
        padding: 0 5px;

        .mat-form-field-suffix {
          top: 0;
        }

        .icon-only {
          .mat-form-field-appearance-legacy .mat-form-field-wrapper {
            padding-bottom: 0;
          }

          .mat-form-field-infix {
            border: none;
            width: auto;

            md-icon {
              font-size: $icon-size;
              height: $icon-size;
              width: $icon-size;
            }

            .mat-select-trigger {
              height: 100%;

              mat-select-trigger {
                align-items: center;
                display: flex;
                justify-content: center;
                height: $icon-size;
                width: $icon-size;
              }

              .flag {
                border-radius: 50%;
                background-size: cover;
                height: 100%;
                width: 100%;
              }
            }
          }

          .mat-form-field-appearance-legacy .mat-form-field-underline {
            display: none;
          }
        }
      }
    }
  }

  @media #{$mat-gt-xs} {
    .header-container {
      .form-row {
        &.first md-icon {
          font-size: 18px;
        }
      }
    }
  }

  @include small-desktop {
    header {
      &.left {
        .menu-container > ul {
          justify-content: left;
        }
      }

      &.right {
        .menu-container > ul {
          justify-content: right;
        }
      }
    }
  }
}


