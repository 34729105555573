@mixin header-mobile($theme) {
  $background: map-get($map: $theme, $key: background);
  $is-dark: map-get($map: $theme, $key: is-dark);
  $primary: map-get($map: $theme, $key: primary);
  $secondary: map-get($map: $theme, $key: secondary);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $header-background: map-get($map: $theme, $key: header);
  $text-color: map-get($map: $theme, $key: text-on-header);
  $submenu-background: if($is-dark, lighten($header-background, 15%), lighten($header-background, 100%));
  $submenu-color: map-get($map: $theme, $key: text-on-header);
  $border-color: map-get($map: $theme, $key: border-color);

  .header-mobile-wrapper {
    display: block;
    height: 100px;
    position: relative;

    .header-mobile-container {
      background: $header-background;
      color: $text-on-primary;

      .logo-container {
        width: 44px;

        .bck {
          background-position: center;
          background-repeat: no-repeat;
          height: 44px;
        }
      }

      .menu-button {
        cursor: pointer;
        margin-left: 10px;
        margin-top: -8px;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        width: 24px;

        span {
          background: $text-color;
          display: block;
          height: 2px;
          left: 0;
          opacity: 1;
          position: absolute;
          transform: rotate(0deg);
          transform-origin: left center;
          transition: .25s ease-in-out;
          width: 100%;

          &:nth-child(1) {
            top: 0;
          }

          &:nth-child(2) {
            top: 7px;
          }

          &:nth-child(3) {
            top: 14px;
          }
        }

        &.open {
          span {
            &:nth-child(1) {
              top: -1px;
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              opacity: 0;
              width: 0;
            }

            &:nth-child(3) {
              top: 16px;
              transform: rotate(-45deg);
            }
          }
        }
      }

      .icon-left .md-icon::before {
        font-size: 26px;
        margin-left: 10px;
      }

      .right-col {
        a,
        button {
          .md-icon::before {
            color: $text-color;
            margin-right: 10px;
          }
        }
      }

      .top-bar {
        border-bottom: 1px solid $border-color;
        height: 60px;

        md-icon{
          color: $text-color;
          font-size: 18px;
        }

        .mdi-account-circle{
          color: $text-color;
          font-size: 28px;
        }

        .deposit-amount {
          color: $text-color;
        }

        .deposit{
          color: $secondary;
        }
      }

      .quick-links {
        background: $background;

        menu {
          background: $submenu-background;
          color: $submenu-color;
        }
      }
    }
  }

  brand-header-selector {
    .menu-container,
    ul {
      background: $submenu-background;
    }

    ul {
      margin: auto;
    }
  }
}
