@mixin page-categories($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $font-size: map-get($map: $theme, $key: font-size);
  $form-row-background: if($is-dark, lighten(rgba($background, 1), 10%), $background);
  $row-color: if($is-dark, lighten($background, 15%), darken($background, 15%));
  $node-height: 45px;

  .page-categories-container {
    &.mobile {
      .mat-form-field {
        width: 100%;
      }

      .select-row {
        background: $row-color;
        position: relative;
        height: $node-height;

        mat-slide-toggle {
          margin-left: 2px;
        }

        .checks-icon {
          font-size: $font-size + 7;

          span {
            margin-right: 5px;
          }
        }
      }
    }

    @media #{$mat-md} {
      .categories-form {
        .mat-form-field {
          width: 100%;
        }
      }
    }

    @include small-desktop {
      .select-row {
        position: sticky;
        top: 0;
        z-index: 1;

        .categories-form {
          background: $form-row-background;
        }
      }
    }
  }
}
