@mixin games-grid($theme) {
  $background: map-get($map: $theme, $key: background);
  $border-color: map-get($theme, border-color);
  $text-color: map-get($map: $theme, $key: text-color);
  $primary: map-get($map: $theme, $key: primary);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $secondary: map-get($map: $theme, $key: secondary);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $font-size: map-get($map: $theme, $key: font-size);
  $radius: map-get($map: $theme, $key: radius);

  // CASINO GAMES GRID
  .games-grid-container,
  .games-list-container,
  .casino-games-grid {
    padding: 15px 0;

    @media #{$mat-lt-md} {
      padding: 15px 10px;
    }

    .games-counter,
    .cat-title {
      font-size: $font-size + 2px;
      font-weight: bold;
      text-transform: uppercase;

      @media #{$mat-lt-sm} {
        font-size: $font-size;
      }

    }

    .expand-btn {
      font-size: $font-size - 2;
      padding: 4px 10px;

      &:hover {
        background: $secondary;
        color: $text-on-primary;
      }

      @media #{$mat-lt-md} {
        margin-left: auto;
      }
    }

    .games-row {

      .col-auto {
        margin-bottom: 10px;
        padding: 0 5px;
      }

      .col-game {
        flex: 0 0 250px;
        padding: 0 5px;

        game-tile {

          .amount {
            align-items: center;
            background: rgba($primary, .75);
            border-radius: 500px;
            bottom: 15px;
            color: $text-on-primary;
            display: flex;
            font-size: 16px;
            font-weight: bold;
            height: 30px;
            justify-content: center;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            width: 160px;

            @media #{$mat-lt-md} {
              font-size: $font-size - 2;
              height: auto;
              padding: 5px 10px;
              width: auto;
            }

          }

          .overlay {
            background: rgba($background, .8);
            border: 2px solid $tertiary;
            border-radius: $radius;
          }

          .game-name-grid {
            display: none;
          }

        }

        @media #{$mat-lt-md} {
          flex: 0 0 33.3%;
        }

        @media #{$mat-lt-sm} {
          flex: 0 0 50%;
        }
      }
    }

    // SWIPER SETTINGS

    .swiper-container {
      .swiper-button-prev {
        border-radius: 0 $radius $radius 0;
        left: 0;

        &:after {
          content: 'prev';
        }
      }

      .swiper-button-next {
        border-radius: $radius 0 0 $radius;
        right: 0;

        &:after {
          content: 'next';
        }
      }

      @include tablet {
        .swiper-slide {
          flex: 0 0 250px;
        }
      }

      @include mobile {
        &.swiper-grid-column > .swiper-wrapper {
          flex-direction: row;

          .swiper-slide {
            margin-top: 0 !important;
            margin-bottom: 6px;
          }
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        align-items: center;
        background: $background;
        color: $text-color;
        display: flex;
        font-size: 28px;
        height: 40px;
        justify-content: center;
        line-height: 1;
        opacity: .8;
        transition: all .3s ease;
        width: 30px;

        &:hover {
          color: $tertiary;
          opacity: 1;
        }

        @media #{$mat-lt-md} {
          display: none;
        }

        &::after {
          font-size: $font-size + 1;
          font-weight: 700;
        }
      }
    }
  }

  .games-grid-container {
    @media #{$mat-lt-md} {
      padding: 0;
    }

    .games-row {
      .col-game {
        flex: 0 0 16.66%;
        margin-bottom: 10px;
        max-width: 16.66%;
        transition: all .2s ease;

        @media #{$mat-lt-xl} {
          flex: 0 0 20%;
          max-width: 20%;
        }

        @media #{$mat-lt-lg} {
          flex: 0 0 25%;
          max-width: 25%;
        }

        @media #{$mat-lt-md} {
          flex: 0 0 33.3%;
          max-width: 33.3%;
        }

        @media #{$mat-lt-sm} {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }

    .grid-games {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      @include tablet {
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));

        game-tile {
          border: 1px solid $border-color;
          border-radius: 6px;

          .overlay {
            background: rgba($background, .8);
            border-radius: 6px 6px 0 0;
            height: calc(100% - 34px);
            opacity: 0;
            pointer-events: none;
            transform: translateY(30%);
            transition: opacity .2s, transform .3s ease;

            .favourites-icon {
              top: 6px;
              right: 6px !important;
            }
          }

          &:hover {
            .overlay {
              transform: translateY(0);
              opacity: 1;
              pointer-events: auto;
            }
          }

          .game-name-grid {
            background: darken($background, 5%);
            border-radius: 0 0 6px 6px;
            display: block;
            padding: 10px;
          }
        }

        .img-fluid {
          border-radius: 6px 6px 0 0;
          display: block;
        }
      }
    }

    .games-lite {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(2, 1fr);

      @include mobile {
        .game-name-grid {
          display: block;
          margin: auto;
          max-width: 166px;
          text-align: center;
        }
      }

      game-tile {
        .favourites-icon {
          bottom: 20px !important;
          right: 6px !important;
        }
      }

      @include tablet {
        gap: 16px;
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));

        game-tile {
          .overlay {
            background: rgba($background, .8);
            height: calc(100% - 24px);
            opacity: 0;
            pointer-events: none;
            transition: opacity .2s;

            .favourites-icon {
              bottom: 6px !important;
            }

            .play-container {
              pointer-events: none;

              a {
                pointer-events: auto;

                &:first-child {
                  margin-top: 8px;
                }

                &:not(:only-child):first-child {
                  margin-top: 44px;
                }

                &:not(:only-child):last-child {
                  background-color: transparent;

                  .mat-button-wrapper {
                    text-decoration: underline;
                  }

                  &:hover {
                    opacity: .6;
                  }
                }
              }
            }
          }

          &:hover {
            .overlay {
              backdrop-filter: blur(8px);
              opacity: 1;
              pointer-events: auto;
            }
          }

          .game-name-grid {
            display: block;
            font-size: $font-size + 1;
            font-weight: normal;
            margin-top: 6px;
            text-align: center;
          }
        }
      }
    }
  }

  @include small-desktop {
    .casino-grid {
      display: grid;
      grid-template-columns: 300px minmax(700px, 1fr);

      .left-side {
        margin-left: 10px;
      }

      .jackpot {
        background: linear-gradient(90deg, rgba(0,0,0, .4), rgba(0,0,0, .8)), url('/assets/images/casino/jackpot.jpg') center;
        background-size: cover;
        border-radius: $radius;
        height: 120px;

        &-title {
          color: $text-on-primary;
          font-size: $font-size + 1;
        }

        &-prize {
          color: $tertiary;
          font-size: $font-size + 3;
        }
      }

      .container-games {
        width: 100%;
      }
    }
  }

}
