@mixin promotions($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $primary-color: map-get($map: $theme, $key: primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $font-size: map-get($map: $theme, $key: font-size);
  $header-background: if($is-dark, lighten($background, 10%), lighten($background, 3%));
  $panel-background: if($is-dark, lighten($background, 10%), lighten($background, 3%));
  $panel-border-color: if($is-dark, rgba($text-on-primary, .12), rgba(#000, .12));
  $shadow-color: if($is-dark, 0 5px 7px 0 rgba(#000, .55), 0 5px 7px 0 rgba(#000, .3));
  $shadow-hover-color: if($is-dark, 0 5px 7px 0 rgba(#000, .55), 0 5px 7px 0 rgba(#000, .3));
  $shadow-small: if($is-dark, 0 2px 2px 1px rgba(#000, .45), 0 2px 2px 1px rgba(#000, .2));
  $sub-card-bg: if($is-dark, lighten($background, 10%), lighten($background, 3%));
  $success: map-get($map: $theme, $key: success);
  $text-on-success: map-get($map: $theme, $key: text-on-success);
  $radius: map-get($map: $theme, $key: radius);

  $img-dir: '/assets/images';

  .promotions-container {
    .no-content {
      line-height: 1.5;

      .icon-bg {
        background: url('#{$img-dir}/sad-face-icon.svg') no-repeat center;
        background-size: contain;
        display: flex;
        filter: contrast(0);
        height: 125px;
        margin: 0 auto 25px;
        width: 125px;
      }
    }

    .achievement-row {
      border-bottom: 1px solid $panel-border-color;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &:last-child {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .col-actions,
      .col-priority {
        flex: 0 0 auto;
        max-width: 100%;
        width: auto;
      }

      .scheduling-expanded {
        margin-top: 15px;
        overflow: hidden;

        .row {
          flex-wrap: nowrap;
          margin: 10px 10px 0 10px;

          .col {
            margin-bottom: 15px;
            padding: 5px 10px;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }

        .day {
          background: $sub-card-bg;
          border-radius: $radius;
          box-shadow: $shadow-small;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: flex-start;
          overflow: hidden;
          text-align: center;

          span {
            @include block-ellipsis;
            font-size: 12px;
            padding: 0 5px 5px 5px;

            &.dayname {
              border-bottom: 1px solid $primary-color;
              color: $tertiary;
              margin-bottom: 5px;
              padding-top: 5px;
            }
          }
        }

        @media #{$mat-lt-md} {
          .ps__rail-x {
            opacity: .8;

            &:hover {
              height: 3px;
            }
          }
        }
      }

      .spendibility-criteria {
        overflow: hidden;

        .row {
          flex-wrap: nowrap;
          margin: 10px 10px 0;

          .col {
            margin-bottom: 15px;
            padding: 5px 10px;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }

        .entry {
          border-radius: $radius;
          box-shadow: $shadow-small;
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;
          padding-bottom: 5px;
          padding-top: 10px;
          text-align: center;

          span {
            display: block;
            font-size: 12px;
            overflow: hidden;
            padding: 0 5px 5px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        @media #{$mat-lt-md} {
          .ps__rail-x {
            opacity: .8;

            &:hover {
              height: 3px;
            }
          }
        }
      }

      .progress-data {
        .icon-counter {
          align-items: center;
          display: flex;
          flex-wrap: nowrap;

          md-icon {
            color: $tertiary;
            display: block;
            font-size: $font-size + 7;
            height: 20px;
            line-height: 1;
            margin: 0 3px;


            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          @media #{$mat-lt-md} {

            md-icon {
              flex: 0 0 10%;
              margin: 0;
            }
          }
        }
      }

      .row {
        margin: 0 -10px;
      }

      .col {
        padding: 0 10px;
      }

      @media #{$mat-lt-md} {

        .col-priority {
          flex: 0 0 52px;
        }

        .col-data {
          flex: 0 0 calc(100% - 52px);
          justify-content: flex-end;
        }

        .col-actions {
          margin-left: auto;
          padding-top: 15px;
        }
      }

      .priority-n {
        align-items: center;
        background: transparent;
        border: 2px solid rgba($text-color, .3);
        border-radius: 500px;
        color: $text-color;
        display: flex;
        font-size: $font-size + 1;
        font-weight: bold;
        height: 32px;
        justify-content: center;
        width: 32px;

        &.reached {
          background: $success;
          border-color: $success;
          color: $text-on-success;
        }
      }

      .scheduling {
        md-icon {
          display: block;
          font-size: $font-size + 8;
          height: 28px;
          margin: 0;
        }
      }
    }

    .mat-tab-body-content {
      padding: 15px;
    }

    .promo-banner {
      box-shadow: $shadow-color;
      object-fit: cover;
      width: 100%;
    }

    // SPECIFIC PROMOTIONS PANEL

    .promo-panel {
      background: $panel-background;
      border-radius: $radius;
      margin-bottom: 15px !important;
      transition: all .2s ease;

      .mat-expansion-panel-header {
        background: $header-background;
        font-size: $font-size;

        @media #{$mat-lt-md} {
          padding: 0 15px
        }

        .mat-expansion-panel-header-title {
          @include block-ellipsis;
        }
      }

      &.mat-expanded {
        box-shadow: $shadow-color !important;
        margin-bottom: 30px !important;

        .mat-expansion-panel-header {
          border-bottom: 1px solid;
          border-color: $panel-border-color;
        }
      }

      .mat-expansion-panel-body {
        padding: 15px;

        .promo-banner {
          display: block;
          margin-bottom: 25px;
        }

        p {
          margin: 0 0 15px;
        }
      }

      &:hover {
        box-shadow: $shadow-hover-color !important;

        @media #{$mat-lt-md} {
          box-shadow: none !important;
        }
      }

      .mat-action-row {
        button {
          margin-left: 15px;

          span {
            @include block-ellipsis;
          }

          &:first-child {
            margin: 0;
          }
        }

        @media #{$mat-lt-md} {
          justify-content: center;
          padding: 15px 10px;
        }
      }
    }

    // END PROMOTION PANEL

    &.single-promotion {
      .new-card {
        margin: 0 !important;
      }

      .mat-card-content {
        padding: 30px 30px 15px;

        @media #{$mat-lt-md} {
          padding: 15px;
        }

        .promo-banner {
          display: block;
          margin-bottom: 30px;
        }

        p {
          margin: 0 0 15px;
        }

        .title-row {

          h2,
          h3 {
            color: $tertiary;
            margin: 0;
            text-transform: uppercase;
          }

          .time-range {
            font-size: $font-size - 2;
            opacity: .8;
          }
        }
      }
    }
  }
}
