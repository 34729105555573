@mixin page-play-game($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $frame-background: if($is-dark, lighten($background, 10%), lighten($background, 100%));
  $sidebar-background: if($is-dark, lighten($background, 5%), lighten($background, 100%));
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $topbar-background: if($is-dark, lighten($background, 5%), lighten($background, 100%));
  $sidecol-background: if($is-dark, lighten($background, 15%), lighten($background, 100%));
  $radius: map-get($map: $theme, $key: radius);

  $frame-size: calc(100vh - 280px);

  $header-height: 150px;

  $right-sidebar: 290px;
  $right-sidebar-compressed: $right-sidebar * .5;

  .play-game-container {
    max-height: calc(100vh - #{$header-height});
    overflow: hidden;

    .real-link {
      color: $text-on-primary;
    }

    %compressed-classes {
      height: 0 !important;
      opacity: 0 !important;
      width: 0 !important;
    }

    .col-left {
      flex: 1 1 100%;
      transition: all .5s ease;
    }

    .col-right {
      background: $sidecol-background;
      flex: 0 0 $right-sidebar;
      transition: all .5s ease;

      .scrollbar {
        max-height: calc(100vh - 240px);
      }

      .new-card {
        background: $sidecol-background;
        margin: 0 !important;
      }

      .games-list-container {
        padding: 10px 5px;
      }

      .icon-bg {
        background: url('/assets/images/add-favourites.svg') no-repeat center;
        background-size: contain;
        display: flex;
        filter: contrast(0);
        height: 100px;
        margin: 30px auto;
        width: 100px;
      }


      &.compressed {
        flex: 0 0 $right-sidebar-compressed;

        .scrollbar {
          max-height: calc(100vh - 190px);
        }

        .new-card {
          .box-header {
            .box-title {

              md-icon {
                @extend %compressed-classes;
              }
            }
          }
        }

        .my-content {

          .mat-tab-header,
          .subtitle {
            display: none;

            .mat-tab-body-content {
              padding: 15px 5px;
            }
          }
        }

        .icon-bg {
          height: 35px;
          margin: 15px 0;
          width: 35px;
        }

        .sub-text {
          display: none !important;
        }

        .games-list-container {
          padding: 15px 10px;

          .games-row {
            .col-game {
              flex: 0 0 100%;
              max-width: 100%;
              padding: 0;
            }
          }
        }
      }
    }

    .max-vh {
      max-height: calc(100vh - #{$header-height});
    }

    .content {
      max-height: calc(100vh - #{$header-height});
      min-height: calc(100vh - #{$header-height});
    }

    iframe {
      height: calc(100vh - 85px);
    }

    &:not(.full) {
      iframe {
        height: $frame-size;
      }
    }

    .frame-container {
      background: $frame-background;
      min-height: $frame-size;
    }

    .game-info-container {
      background: $topbar-background;
      height: 90px;

      h1 {
        color: $tertiary;
        line-height: 1.1;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      .thumbnail-container {
        padding: 10px;

        img {
          border-radius: $radius;
          width: 100px;
        }
      }

      ul {
        list-style: none;

        li {
          display: inline-block;
          font-size: 14px;
          padding: 5px;
          text-transform: capitalize;

          .highlight {
            color: $tertiary;
          }
        }
      }
    }

    .sidebar-container {
      background: $sidebar-background;

      .max-sidebar-h {
        max-height: calc(100vh - 200px);
      }
    }
  }
}
