$mat-xs: 'screen and (max-width: 767px)';
$mat-sm: 'screen and (min-width: 768px) and (max-width: 991px)';
$mat-md: 'screen and (min-width: 960px) and (max-width: 1279px)';
$mat-lg: 'screen and (min-width: 1280px) and (max-width: 1919px)';
$mat-xl: 'screen and (min-width: 1920px) and (max-width: 5000px)';
$mat-lt-xs: 'screen and (max-width: 576px)';
$mat-lt-sm: 'screen and (max-width: 767px)';
$mat-lt-md: 'screen and (max-width: 991px)';
$mat-lt-md-l: 'screen and (max-width: 1024px)';
$mat-lt-lg: 'screen and (max-width: 1279px)';
$mat-lt-xl: 'screen and (max-width: 1919px)';
$mat-gt-xs: 'screen and (min-width: 768px)';
$mat-gt-sm: 'screen and (min-width: 992px)';
$mat-gt-md: 'screen and (min-width: 1280px)';
$mat-gt-xl: 'screen and (min-width: 1920px)';

$success-color: #27AE60;
$warning-color: #F9C22A;
$danger-color: #FA461C;
$info-color: #0C8BB9;
$error-color: #D74141;

@media #{$mat-lt-md} {
  #launcher {
    display: none;
  }
}

@mixin mobile {
  @media #{$mat-lt-sm} {
    @content;
  }
}

@mixin tablet {
  @media #{$mat-gt-xs} {
    @content;
  }
}

@mixin small-desktop {
  @media #{$mat-gt-sm} {
    @content;
  }
}

@mixin desktop {
  @media #{$mat-gt-md} {
    @content;
  }
}

@mixin orientation-landscape {
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    @content;
  }
}

.mobile-only {
  @include tablet {
    display: none;
  }
}

.tablet-only {
  @include mobile {
    display: none;
  }
}

.icon-xl {
  font-size: 16px;
}

.ws-100vw {
  word-spacing: 100vw;
}

.mobile-extend {
  @media #{$mat-lt-sm} {
    width: 100%;
  }
}

.min-width-no {
  min-width: unset !important;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-normal {
  font-weight: normal;
}

.lh-1 {
  line-height: 1;
}

.uppercase {
  text-transform: uppercase;
}

.img-fluid {
  height: auto;
  max-width: 100%;
}

.padded {
  padding: 5px !important;
}

.border-radius-top-none {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border-radius-bottom-none {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.position-relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}


// Padding
//.p-0 {
//  padding: 0;
//}
//
//.p-5 {
//  padding: 5px;
//}
//
//.p-10 {
//  padding: 10px;
//}
//
//.p-15 {
//  padding: 15px;
//}
//
//.p-20 {
//  padding: 20px;
//}
//
//.p-30 {
//  padding: 30px;
//}
//
//.p-l-0 {
//  padding-left: 0;
//}
//
//.p-l-5 {
//  padding-left: 5px;
//}
//
//.p-l-10 {
//  padding-left: 10px;
//}
//
//.p-l-15 {
//  padding-left: 15px;
//}
//
//.p-l-20 {
//  padding-left: 20px;
//}
//
//.p-r-0 {
//  padding-right: 0;
//}
//
//.p-r-5 {
//  padding-right: 5px;
//}
//
//.p-r-10 {
//  padding-right: 10px;
//}
//
//.p-r-15 {
//  padding-right: 15px;
//}
//
//.p-r-20 {
//  padding-right: 20px;
//}
//
//.p-r-30 {
//  padding-right: 30px;
//}
//
//.p-r-40 {
//  padding-right: 40px;
//}
//
//.p-t-0 {
//  padding-top: 0;
//}
//
//.p-t-5 {
//  padding-top: 5px;
//}
//
//.p-t-10 {
//  padding-top: 10px;
//}
//
//.p-t-15 {
//  padding-top: 15px;
//}
//
//.p-t-20 {
//  padding-top: 20px;
//}
//
//.p-t-25 {
//  padding-top: 25px;
//}
//
//.p-t-30 {
//  padding-top: 30px;
//}
//
//.p-b-0 {
//  padding-bottom: 0;
//}
//
//.p-b-5 {
//  padding-bottom: 5px;
//}
//
//.p-b-10 {
//  padding-bottom: 10px;
//}
//
//.p-b-15 {
//  padding-bottom: 15px;
//}
//
//.p-b-20 {
//  padding-bottom: 20px;
//}
//
//.p-b-25 {
//  padding-bottom: 25px;
//}
//
//.p-b-30 {
//  padding-bottom: 30px;
//}
//
//.p-b-40 {
//  padding-bottom: 40px;
//}
//
//// Margin
//
//.m-l-5 {
//  margin-left: 5px;
//}
//
//.m-l-10 {
//  margin-left: 10px;
//}
//
//.m-l-15 {
//  margin-left: 15px;
//}
//
//.m-l-20 {
//  margin-left: 20px;
//}
//
//.m-l-30 {
//  margin-left: 30px;
//}
//
//.m-l-40 {
//  margin-left: 40px;
//}
//
//.m-r-5 {
//  margin-right: 5px;
//}
//
//.m-r-10 {
//  margin-right: 10px;
//}
//
//.m-r-15 {
//  margin-right: 15px;
//}
//
//.m-r-20 {
//  margin-right: 20px;
//}
//
//.m-r-30 {
//  margin-right: 30px;
//}
//
//.m-r-40 {
//  margin-right: 40px;
//}
//
//.m-t-0 {
//  margin-top: 0;
//}
//
//.m-t-5 {
//  margin-top: 5px;
//}
//
//.m-t-10 {
//  margin-top: 10px;
//}
//
//.m-t-15 {
//  margin-top: 15px;
//}
//
//.m-t-20 {
//  margin-top: 20px;
//}
//
//.m-t-30 {
//  margin-top: 30px;
//}
//
//.m-t-40 {
//  margin-top: 40px;
//}
//
//.m-b-0 {
//  margin-bottom: 0;
//}
//
//.m-b-5 {
//  margin-bottom: 5px;
//}
//
//.m-b-10 {
//  margin-bottom: 10px;
//}
//
//.m-b-15 {
//  margin-bottom: 15px;
//}
//
//.m-b-20 {
//  margin-bottom: 20px;
//}
//
//.m-b-30 {
//  margin-bottom: 30px;
//}
//
//.m-b-40 {
//  margin-bottom: 40px;
//}

.flag-rounded{
  border-radius: 50%;
  overflow: hidden;
  height: 16px;
  width: 16px;
}

[hidden],
.hidden {
  display: none !important;
}

//SVG
//--------------------------
.fill-current {
  fill: currentcolor !important;
}

@media #{$mat-lt-md} {
  .vh-100-center {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    justify-content: center;
  }
}

.error {
  color: $error-color;

  &.mat-snack-bar-container {
    background-color: $error-color;

    .mat-simple-snackbar {
      color: #FFF;
    }

    // .mat-simple-snackbar-action {}
  }
}

.success {
  color: $success-color;

  &.mat-snack-bar-container {
    background-color: $success-color;

    .mat-simple-snackbar {
      color: #FFF;
    }
  }
}

.loading-overlay {
  background-color: rgba(0, 0, 0, .1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  mat-spinner {
    left: calc(50% - 22px);
    position: absolute;
    top: calc(50% - 22px);
  }
}

pre {
  font: 12px monospace;

  &.floating {
    background-color: #FFF;
    border-radius: 5px;
    bottom: 10px;
    box-shadow: 1px 1px 2px #111;
    color: #000;
    max-width: 30vw;
    overflow-y: auto;
    padding: 10px;
    position: fixed;
    right: 10px;
    text-overflow: ellipsis;
    top: 10px;
    z-index: 100;

    &.left {
      right: auto;
      left: 10px;
    }
  }
}

.position-relative {
  position: relative;
}
