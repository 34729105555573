@mixin tables($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $border-color: map-get($map: $theme, $key: border-color);
  $font-size: map-get($map: $theme, $key: font-size);
  $primary-color: map-get($map: $theme, $key: primary);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $second-row-background: if($is-dark, lighten($background, 2%), lighten($background, 2%));
  $overlay-background: map-get($theme, overlaybackground);
  $date-col-background: if($is-dark, $overlay-background, lighten($background, 100%));
  $table-bg: if($is-dark, lighten($background, 8%), lighten($background, 100%));
  $table-header-bg: if($is-dark, lighten($background, 10%), lighten($background, 100%));
  $row-hover-background: if($is-dark, lighten($background, 5%), lighten($background, 2%));
  $odd-cell-hover-background: if($is-dark, lighten($background, 20%), lighten($background, 3%));
  $outcome-color: map-get($map: $theme, $key: outcome-color);
  $drawer-header-background: if($is-dark, $background, $text-on-primary);
  $drawer-table-border-color: if($is-dark, lighten($background, 5%), $text-on-primary);

  table {

    &.table-responsive {
      display: block;
      overflow-x: auto;
    }

    &.requirements {
      th,
      td {
        border-bottom: 1px solid rgba(0, 0, 0, .23);
        border-right: 1px solid rgba(0, 0, 0, .23);
        font-size: $font-size - 1;

        &:last-child {
          border-right: none;
        }
      }

      tr:last-child {
        th,
        td {
          border-bottom: none;
        }
      }

      .date-column {
        display: flex;
      }
    }

    &.tournaments {
      background: $background;
      border-collapse: collapse;
      border-spacing: 0;

      tr {
        transition: background-color .2s ease;

        th,
        td {
          font-size: $font-size;
          height: 36px;
          padding: 0 5px;
          position: relative;
        }

        td {
          min-width: 60px;
          padding: 5px 5px;
          text-align: center;

          &.name {
            text-align: left;
            width: 100%;
          }
        }

        &:hover {
          td {
            background-color: $row-hover-background;
          }
        }

        &:first-child {
          th {
            background-color: $date-col-background;
            border-right: 1px solid $border-color;

            &:last-child {
              border-right: none;
            }
          }
        }

        &:nth-child(2) {
          th {
            background-color: $second-row-background;

            &:first-child {
              text-align: center;
            }
          }
        }

        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }

      td {
        background-color: $table-header-bg;
        border-bottom: 1px solid $border-color;

        &:not(:last-child) {
          border-right: 1px solid $border-color;
        }

        &.name {
          .ellipsis {
            max-width: calc(100vw - 190px);
          }

          .star-icon {
            font-size: $font-size + 3;
          }

          .score {
            color: $outcome-color;
            margin-left: 10px;
            white-space: nowrap;
          }
        }

        &.scores {
          color: $outcome-color;
          min-width: 80px;
        }

        &.odd {
          border-color: transparent;

          .odd-won {
            align-items: center;
            background-color: $outcome-color;
            display: flex;
            height: 100%;
            justify-content: center;
          }

          .odd-lost,
          .odd-won {
            font-size: $font-size - 1;
            text-transform: uppercase;
          }

          &:hover {
            button {
              background-color: $odd-cell-hover-background;
            }
          }

          button {
            color: $outcome-color;
            font-weight: bold;

            &:disabled {
              background-color: $table-header-bg;
            }

            &.active {
              background-color: $primary-color;
              color: $text-on-primary;
            }
          }
        }
      }
    }

    &.dtb {
      display: flex;
      flex-direction: column;

      td.hide-mobile,
      thead {
        display: none;
      }

      tr {
        display: flex;
        flex-direction: column;

        &:hover {
          background-color: transparent;
        }

        td {
          align-items: center;
          border-top: none;
          display: flex;
          font-size: $font-size - 1;
          justify-content: space-between;
        }
      }
    }

    &.drawer.mat-table {
      .mat-header-row .mat-header-cell {
        background: $drawer-header-background;
        border-bottom: none;

        &:first-child {
          padding-left: 15px;
        }

        &:last-child {
          padding-right: 15px;
        }
      }

      .mat-row .mat-cell {
        border-bottom-color: $drawer-table-border-color;

        &:first-child {
          padding-left: 15px;
        }

        &:last-child {
          padding-right: 15px;
        }
      }
    }
  }

  @media #{$mat-lt-sm} {
    table {
      &.requirements {
        td {
          height: auto;
          margin-bottom: 4px;
          text-align: right;

          > div {
            &:first-child {
              font-weight: bold;
            }
          }

          &.date-column {
            flex-wrap: wrap;

            .promotions-buttons {
              margin-top: 10px;
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media #{$mat-gt-xs} {
    table {

      &.table-responsive {
        display: table;
      }

      &.tournaments {
        thead {
          &:not(:first-child)::before {
            content: '';
            display: table-row;
            height: 6px;
          }
        }

        td {
          background-color: $table-bg;

          &.odd {
            min-width: 80px;

            button {

              &:disabled {
                background-color: $table-bg;
              }

              &.active {
                background-color: $primary-color;
              }
            }
          }
        }
      }

      &.dtb {
        display: table;

        thead {
          display: table-header-group;
        }

        .mobile-only {
          display: none;
        }

        tr {
          display: table-row;

          td,
          td.hide-mobile {

            display: table-cell;
            font-size: $font-size + 3;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
