@mixin alert($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $font-size: map-get($map: $theme, $key: font-size);
  $primary-color: map-get($map: $theme, $key: primary);
  $secondary-color: map-get($map: $theme, $key: secondary);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $text-color: map-get($map: $theme, $key: text-color);
  $panel-background: if($is-dark, lighten($background, 10%), lighten($background, 5%));
  $shadow-color: if($is-dark, 0 3px 6px 0 rgba(#000, .35), 0 3px 6px 0 rgba($text-color, .25));

  $radius: map-get($map: $theme, $key: radius);
  $success: map-get($map: $theme, $key: success);
  $danger: map-get($map: $theme, $key: danger);
  $warning: map-get($map: $theme, $key: warning);

  .alert {
    background: $panel-background;
    border-left: 5px solid transparent;
    border-radius: $radius;
    box-shadow: $shadow-color;
    padding: 10px;

    &.alert-primary {
      border-color: rgba($primary-color, .9);
      color: $text-color;
    }

    &.alert-secondary {
      border-color: rgba($secondary-color, .9);
      color: $text-color;
    }

    &.alert-success {
      border-color: $success;
      color: $text-color;
    }

    &.alert-warning {
      border-color: $warning;
      color: $text-color;
    }

    &.alert-danger {
      border-color: $danger;
      color: $text-color;
    }

    &.alert-info {
      border-color: $tertiary;
      color: $text-color;
    }

    .mat-button-base {
      font-size: $font-size + 3;
      height: auto;
      padding: 0 5px;
      width: auto;
    }
  }
}
