@mixin inputs($theme) {
  $background: map-get($map: $theme, $key: background);
  $primary-color: map-get($map: $theme, $key: primary);
  $button-y-padding: 8px;
  $radius: map-get($map: $theme, $key: radius);
  $danger: map-get($map: $theme, $key: danger);
  $pin-box-size: 42px;

  .mat-mdc-form-field {

    &.mat-form-field-appearance-outline {
      .mat-form-field-outline-start {
        border-radius: $radius 0 0 $radius;
        border-width: 2px;
      }

      .mat-form-field-outline-end {
        border-radius: 0 $radius $radius 0;
        border-width: 2px;
      }

      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-infix {
          padding: #{$button-y-padding - 4} 0;
        }
      }
    }
  }

  .pin-input {
    background-color: $background;
    border: 1px solid $primary-color;
    color: $primary-color;
    font-size: 2em;
    line-height: 1;
    margin: 4px;
    max-height: $pin-box-size;
    max-width: $pin-box-size;
    padding: 1ex;
    text-align: center;

    &:focus {
      border: 2px solid darken($primary-color, 10%);
    }

    &.ng-invalid.ng-touched {
      border-color: $danger;
    }
  }
}
