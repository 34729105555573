.mat-select-panel.network-transfer {
  margin: 25px 15px 0;
  width: 100%;
}

@mixin dialogs($theme) {
  $text-color: map-get($map: $theme, $key: text-color);
  $font-size: map-get($map: $theme, $key: font-size);
  $card-header-height: 48;

  .mat-mdc-dialog-container {
    padding: 0;

    .mdc-dialog__surface {
      background-color: transparent;
    }
  }

  .dialog {

   /* mobile-prefix.mat-form-field {
      display: inline-flex;
    }*/

    .mat-mdc-card {
      position: relative;

      &-content {
        padding: 0;
      }

      .dialog-body {
        padding: 12px;
      }

      &.new-card {
        margin: 0;

        .mat-mdc-card-title {
          align-items: center;
          background-color: transparent;
          display: flex;
          font-size: $font-size - 1;
          font-weight: 700;
          height: #{$card-header-height}px;
          justify-content: space-between;
          padding: 0 10px;
        }
      }
    }

    .close-icon {
      background-color: transparent;
      border: none;
      color: $text-color;
      cursor: pointer;
      font-size: $font-size + 3;
      padding: 0;
    }
  }

  .withdrawal-dialog {

    .bank-transfer-success {
      text-align: center;

      .confirm-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0 0 10px;

        img {
          height: 80px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, .7);
  }

  @media #{$mat-lt-sm} {
    .cdk-overlay-pane {
      max-width: 100vw !important;
    }

    .mat-mdc-dialog-container {
      max-width: 100vw;
      min-height: 100vh;
      min-width: 0;
      width: 100vw;
    }
  }

  @media #{$mat-gt-xs} {
    .dialog .mat-mdc-card .dialog-body {
      padding: 25px;
    }
  }
}
