@mixin tooltip() {
  .mat-tooltip {
    background: #111419 0 0 no-repeat padding-box;
    box-shadow: 0 20px 20px #00000079;
    opacity: 1;
    text-align: center;
    white-space: pre-line;
  }

}
