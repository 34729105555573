@mixin referral-link($theme) {
  .referral-link-wrapper {
    .sharer-container {
      background: #2D2D2D;
    }

    .link-box {
      background: #424242;
    }
  }
}
