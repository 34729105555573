// VENDOR
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

//  VARIABLES
//@import 'variables/sportscolor';
@import 'variables/theme-variables';
// UTILITIES
@import 'utilities/mixins';
@import 'utilities/utilities';
@import 'utilities/reset';
// PARTIALS
@import 'partials/tags';
//@import 'partials/icons';
@import 'partials/scrollbars';
//@import 'partials/three-columns';

// Components
@import 'components/alert';
@import 'components/animations';
@import 'components/badge';
@import 'components/banner-slider';
//@import 'components/bet-checker';
//@import 'components/bet-settings';
//@import 'components/bet-settings-new';
//@import 'components/betslip';
//@import 'components/betslip-jackpot';
//@import 'components/betslip-promotions';
//@import 'components/bonus-freebets';
//@import 'components/bonus-real';
@import 'components/bonuses';
//@import 'components/booking-bet';
@import 'components/bottom-bar';
@import 'components/box';
//@import 'components/button-toggle-group';
@import 'components/buttons';
@import 'components/card';
@import 'components/common-search';
@import 'components/containers';
//@import 'components/content-widget';
@import 'components/countdown';
//@import 'components/customs';
//@import 'components/daily-offer';
@import 'components/dialogs';
@import 'components/double-range-slider';
//@import 'components/event-stream-box';
//@import 'components/events-table';
@import 'components/expansion-panel';
//@import 'components/fast-bets';
@import 'components/field';
@import 'components/footer';
@import 'components/footer-full-width';
@import 'components/games-categories-menu';
@import 'components/games-grid';
@import 'components/games-list';
@import 'components/generic';
@import 'components/header';
@import 'components/header-mobile';
@import 'components/hero-banner';
//@import 'components/home-sport-live';
@import 'components/homepage-games';
@import 'components/inputs';
@import 'components/language-selector-dialog';
//@import 'components/live-now';
//@import 'components/live-now-event';
//@import 'components/live-score-box';
@import 'components/login-dialog';
//@import 'components/match-in-play';
//@import 'components/match-team-percentage';
//@import 'components/matches-odds';
@import 'components/material';
@import 'components/material-settings';
@import 'components/menu';
@import 'components/mobile-prefix';
//@import 'components/mobile-tickets';
//@import 'components/most-played-match';
//@import 'components/my-bets';
@import 'components/new-container';
@import 'components/numeric-input';
//@import 'components/odds-filter';
//@import 'components/odds-group-box';
//@import 'components/outcome-cell';
//@import 'components/play-now-live';
@import 'components/poker-grid';
//@import 'components/pre-build-coupons';
//@import 'components/product-selector';
@import 'components/promotions';
@import 'components/read-more';
@import 'components/referral-link';
@import 'components/registration-dialog';
@import 'components/registration-new';
@import 'components/requirements-table';
@import 'components/search-events';
@import 'components/search-events-dropdown';
@import 'components/settings-dialog';
@import 'components/sharer';
@import 'components/sidebar';
@import 'components/sidebar-banner';
@import 'components/sidebar-column';
//@import 'components/single-event';
//@import 'components/sport-bar';
//@import 'components/sport-card';
//@import 'components/sport-categories-list';
//@import 'components/sport-events-list';
//@import 'components/sport-favourites';
//@import 'components/sport-menu';
//@import 'components/sport-menu-filter';
//@import 'components/sport-quick-links';
//@import 'components/sport-stream-box';
//@import 'components/sports-bar';
//@import 'components/sports-tag';
//@import 'components/sportsbook-betslip';
//@import 'components/sportsbook-menu';
//@import 'components/sportsbook-printer';
@import 'components/swiper';
@import 'components/tables';
@import 'components/theme-switcher';
//@import 'components/ticket-details-dialog';
@import 'components/time-bar';
@import 'components/tooltip';
@import 'components/top-bar-mobile';
//@import 'components/top-league';
@import 'components/casino-filters';
//@import 'components/top-offer';
//@import 'components/top-winnings';
//@import 'components/tournament-box';
//@import 'components/tournament-table';
@import 'components/tree-table';
@import 'components/view-selector';
@import 'components/wallets-viewer';
//@import 'components/boosted-matches';
@import 'components/stake';
@import 'components/casino-tournament';

// Pages
//@import 'pages/sportsbook/jackpot-details';
//@import 'pages/sportsbook/jackpot-result';
//@import 'pages/virtual-kiron';
@import 'pages/agent-login';
@import 'pages/balance';
@import 'pages/browse-casino';
@import 'pages/casino-live';
@import 'pages/categories';
@import 'pages/crash-games';
@import 'pages/home-casino';
@import 'pages/home-casino-lite';
//@import 'pages/live-container';
//@import 'pages/live-event';
//@import 'pages/live-overview';
@import 'pages/page-play-game';
@import 'pages/page-poker';
@import 'pages/page-promotions';
@import 'pages/page-register';
@import 'pages/page-virtuals';
@import 'pages/promotions-details';
@import 'pages/referral';
@import 'pages/report-cashier';
//@import 'pages/sport-container';
//@import 'pages/sport-home';
//@import 'pages/sportsbook/categories';
//@import 'pages/sportsbook/daily-offer';
//@import 'pages/sportsbook/event-view';
//@import 'pages/sportsbook/favourites-teams';
@import 'pages/calendar-page';
//@import 'pages/sportsbook/page-favorites';
@import 'pages/content-page';
//@import 'pages/sportsbook/single-event';
//@import 'pages/sportsbook/single-sport';

@mixin theme-core($theme) {
  @include material($theme);
  @include material-settings($theme);
  // Components
  @include alert($theme);
  @include animations($theme);
  @include badge($theme);
  @include banner-slider($theme);
  //@include bet-checker($theme);
  //@include bet-settings($theme);
  //@include bet-settings-new($theme);
  //@include betslip($theme);
  //@include betslip-jackpot($theme);
  //@include betslip-promotions($theme);
  //@include bonus-freebets($theme);
  //@include bonus-real($theme);
  @include bonuses($theme);
  //@include booking-bet($theme);
  @include bottom-bar($theme);
  @include box($theme);
  //@include button-toggle-group($theme);
  @include buttons($theme);
  @include card($theme);
  @include common-search($theme);
  @include containers($theme);
  //@include content-widget($theme);
  @include countdown($theme);
  //@include daily-offer($theme);
  @include dialogs($theme);
  @include double-range-slider($theme);
  //@include event-stream-box($theme);
  //@include events-table($theme);
  @include expansion-panel($theme);
  //@include fast-bets($theme);
  @include field($theme);
  @include footer($theme);
  @include footer-full-width($theme);
  @include games-categories-menu($theme);
  @include games-grid($theme);
  @include games-list($theme);
  @include generic($theme);
  @include header($theme);
  @include header-mobile($theme);
  @include hero-banner($theme);
  //@include home-sport-live($theme);
  @include homepage-games($theme);
  @include inputs($theme);
  @include language-selector-dialog($theme);
  //@include live-now($theme);
  //@include live-now-event($theme);
  //@include live-score-box($theme);
  @include login-dialog($theme);
  //@include match-in-play($theme);
  //@include match-team-percentage($theme);
  //@include matches-odds($theme);
  @include menu($theme);
  @include mobile-prefix($theme);
  //@include mobile-tickets($theme);
  //@include most-played-match($theme);
  //@include my-bets($theme);
  @include new-container($theme);
  @include numeric-input($theme);
  //@include odds-filter($theme);
  //@include odds-group-box($theme);
  //@include outcome-cell($theme);
  //@include play-now-live($theme);
  @include poker-grid($theme);
  //@include pre-build-coupons($theme);
  //@include product-selector($theme);
  @include promotions-component($theme);
  @include read-more($theme);
  @include referral-link($theme);
  @include registration-dialog($theme);
  @include registration-new($theme);
  @include requirements-table($theme);
  @include search-events($theme);
  @include search-events-dropdown($theme);
  @include settings-dialog($theme);
  @include sharer($theme);
  @include sidebar($theme);
  @include sidebar-banner($theme);
  @include sidebar-column($theme);
  //@include single-event($theme);
  //@include sport-bar($theme);
  //@include sport-card($theme);
  //@include sport-categories-list($theme);
  //@include sport-events-list($theme);
  //@include sport-favourites($theme);
  //@include sport-menu($theme);
  //@include sport-menu-filter($theme);
  //@include sport-stream-box($theme);
  //@include sports-bar($theme);
  //@include sports-tag($theme);
  //@include sportsbook-betslip($theme);
  //@include sportsbook-menu($theme);
  //@include sportsbook-printer($theme);
  @include swiperjs($theme);
  @include tables($theme);
  @include theme-switcher($theme);
  //@include ticket-details-dialog($theme);
  @include time-bar($theme);
  @include tooltip();
  @include top-bar-mobile($theme);
  //@include top-league($theme);
  //@include top-offer($theme);
  //@include top-winnings($theme);
  //@include tournament-box($theme);
  //@include tournament-table($theme);
  @include tree-table($theme);
  @include casino-filters($theme);
  @include view-selector($theme);
  @include wallets-viewer($theme);
  //@include boosted-matches($theme);
  @include stake($theme);
  @include casino-tournament($theme);

  // Pages
  @include agent-login-page($theme);
  //@include daily-offer($theme);
  //@include event-view($theme);
  //@include favourites-teams-page($theme);
  //@include live-container($theme);
  @include page-balance($theme);
  @include page-browse-casino($theme);
  @include page-casino-lite($theme);
  @include page-casino-live($theme);
  @include page-categories($theme);
  @include page-crash-games($theme);
  @include page-home-casino($theme);
  //@include page-jackpot-details($theme);
  //@include page-jackpot-results($theme);
  //@include page-live-event($theme);
  //@include page-live-overview($theme);
  @include page-play-game($theme);
  @include page-poker($theme);
  @include page-register($theme);
  //@include page-sport-home($theme);
  //@include page-sportsbook-categories($theme);
  //@include page-virtual-kiron($theme);
  @include page-virtuals($theme);
  @include promotion-details($theme);
  @include promotions($theme);
  @include referral($theme);
  @include calendar-page($theme);
  @include report-cashier($theme);
  //@include single-event-page($theme);
  //@include single-sport-page($theme);
  @include content-page($theme);
  //@include sport-container($theme);
  //@include sport-quick-links($theme);
  //@include sportsbook-favorites($theme);

  // customs at the bottom so it overrides everything
  //@include customs($theme);
}

body {
  @include theme-core($dark-theme);

  .logo-container .bck {
    background-size: cover;
    background-image: url('assets/images/logo.png');
  }

  &.light {
    @include theme-core($light-theme);

    .logo-container .bck {
      background-image: url('assets/images/logo.png');
    }
  }

  @include orientation-landscape {
    .games-container {
      bottom-bar {
        display: none !important;
      }

      .home-casino-container .casino-wrapper .scrollable-col {
        max-height: calc(100vh - 100px);
      }

      .casino-games-grid .swiper-container .slide-container {
        width: 200px !important;
      }

      .games-grid-container .games-row .col-game {
        max-width: 25%;
      }
    }
  }

}
