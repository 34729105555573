@mixin poker-grid($theme) {

  .poker-container {
    .poker-grid {
      padding: 15px;

      .new-card {
        margin: 0 !important;
        position: relative;

        .box-anchor {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          overflow: hidden;
          position: relative;

          .game-icon {
            background-position: center;
            background-repeat: no-repeat;
            display: block;
            margin: 75px auto;
            opacity: .8;
            position: relative;
            transform: scale(.95);
            transition: all .4s ease;
            width: 180px;
            z-index: 2;
          }

          .img-bg {
            display: block;
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
          }

          .play-now {
            display: block;
            margin: auto auto 40px;
            padding: 10px 35px;
            position: relative;
            transition: all .5s ease;
            width: 110px;
            z-index: 2;
          }
        }

        &:hover {
          .box-anchor {
            .game-icon {
              opacity: 1;
              transform: translateY(-25px) scale(1.05);
            }
          }
        }

        @media #{$mat-lt-md} {
          .box-anchor {
            .game-icon {
              margin: 50px auto;
              opacity: 1;
              transform: translateY(0) scale(1);
              width: 150px;
            }

            .play-now {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
