@mixin page-home-casino($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $text-color: map-get($map: $theme, $key: text-color);
  $primary: map-get($map: $theme, $key: primary);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $sidecol-background: if($is-dark, lighten($background, 15%), lighten($background, 5%));
  $mycontent-card: if($is-dark, lighten($background, 10%), lighten($background, 3%));
  $font-size: map-get($map: $theme, $key: font-size);
  $radius: map-get($map: $theme, $key: radius);

  $left-sidebar: 200px;
  $left-sidebar-compressed: 55px;

  $right-sidebar: 290px;
  $right-sidebar-compressed: $right-sidebar * .5;


  .home-casino-container {

    .casino-wrapper {
      .no-content {
        line-height: 1.5;

        .icon-bg {
          background: url('/assets/images/sad-face-icon.svg') no-repeat center;
          background-size: contain;
          display: flex;
          filter: contrast(0);
          height: 125px;
          margin: 0 auto 25px;
          width: 125px;
        }
      }

      %compressed-classes {
        height: 0 !important;
        opacity: 0 !important;
        width: 0 !important;
      }

      .slider-container {
        .swiper-container {
          height: 300px;

          @media #{$mat-lt-sm} {
            height: 180px;
          }

          .swiper-pagination-bullet {
            height: 10px;
            width: 10px;

            &.swiper-pagination-bullet-active {
              background: $primary;
            }
          }

          .slide-container {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            .slide-content-container {
              font-weight: bold;
              margin: 0 30px;

              @media #{$mat-lt-md} {
                bottom: 0;
                color: $text-on-primary;
                height: 100%;
                line-height: 1;
                margin: 0;
                padding: 0 15px;
                position: absolute;

                .text-container {
                  background: rgba(#000, .5);
                  height: 25px;
                  left: 0;
                  padding: 0 15px;
                  position: absolute;
                  top: 0;
                  width: 100%;

                  h1 {
                    margin-top: 6px;
                  }
                }

                .button-container {
                  bottom: 0;
                  left: 0;
                  margin: 0;
                  padding: 0 15px;
                  position: absolute;
                  width: auto;

                  a {
                    font-size: $font-size - 3;
                    margin-bottom: 5px;
                    padding: 5px 10px;
                  }
                }
              }

              .slide-title {
                color: $text-on-primary;
                margin-bottom: 0;

                @media #{$mat-lt-md} {
                  @include ellipsis;
                  color: $text-color;
                  font-size: $font-size;
                  margin: 0;
                }
              }

              .slide-content {
                color: $text-on-primary;
                font-size: $font-size;
                padding: 15px 0;

                @media #{$mat-lt-md} {
                  display: none;
                }
              }

              a {
                text-transform: uppercase;
              }
            }
          }
        }
      }

      .loading-container {
        margin: 50px 0;

        h4 {
          padding: 15px 0;
        }
      }

      .col-left {
        background: $sidecol-background;
        flex: 0 0 $left-sidebar;
        padding: 0;

        @media #{$mat-lt-md} {
          display: none;
        }

        .casino-menu {
          ul {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 10px 0;
            width: 100%;

            li {
              align-items: center;
              display: flex;
              margin-top: 5px;
              width: 100%;

              &.view-more {
                display: none;

                button {
                  padding: 15px 0;

                  md-icon {
                    color: $tertiary;
                    font-size: $font-size + 11;
                    margin: 0 auto;
                  }
                }
              }

              a {
                align-items: center;
                color: $text-color;
                display: flex;
                height: 35px;
                padding: 0 10px 0 12px;
                text-transform: capitalize;
                transition: all .15s ease;
                width: 100%;

                span {
                  @include block-ellipsis;
                }

                &:hover {
                  background: rgba(#000, .1);
                }

                .icon-category,
                md-icon {
                  font-size: $font-size + 7;
                  margin-right: 10px;
                }
              }

              &.heading {
                font-size: $font-size - 2;
                font-weight: bold;
                margin-bottom: 5px;
                margin-top: 25px;
                padding: 0 10px;
                text-transform: uppercase;
              }

              &.active {
                a {
                  background: rgba(#000, .1);
                  box-shadow: inset 2px 0 0 0 $tertiary;
                  color: $tertiary;
                  font-weight: bold;
                }
              }
            }
          }
        }

        // compressing menu sidebar
        &.compressed {
          flex: 0 0 $left-sidebar-compressed;

          .new-card {
            .box-header {
              .box-title {
                @extend %compressed-classes;

                span {
                  @extend %compressed-classes;
                }
              }
            }

            .mat-card-title {
              &.has-action {
                .sidebar-action {
                  left: 0;
                  margin: auto;
                  right: 0;
                }
              }
            }

            .ps--active-y > .ps-content {
              margin-right: 0;
            }

            .ps__rail-x,
            .ps__rail-y {
              display: none;
            }

            .casino-menu {
              ul {
                padding: 0;

                li {
                  margin: 0;

                  &.view-more {
                    display: block;
                  }

                  &.heading {
                    @extend %compressed-classes;
                    display: none;
                  }

                  a {
                    height: auto;
                    justify-content: center;
                    padding: 10px;

                    md-icon {
                      font-size: $font-size + 9;
                      margin: 0;
                    }

                    .icon-category {
                      margin: 0 auto !important;
                    }

                    .category-name {
                      @extend %compressed-classes;
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .col-center {
        @media #{$mat-lt-md} {
          padding: 0 !important;
        }

        .scrollbar {
          overflow: visible;

          .ps__rail-y {
            right: -3px !important;
          }
        }
      }

      .col-right {
        background: $sidecol-background;
        flex: 0 0 $right-sidebar;
        padding: 0;

        @media #{$mat-lt-md} {
          display: none;
        }

        .mat-tab-body-content {
          padding: 15px 10px;
        }

        .my-content {
          .subtitle {
            @include block-ellipsis;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: center;
            text-transform: uppercase;
          }

          .icon-bg {
            background: url('/assets/images/add-favourites.svg') no-repeat center;
            background-size: contain;
            display: flex;
            filter: contrast(0);
            height: 100px;
            margin: 0 auto 30px;
            transition: all .5s ease;
            width: 100px;
          }

          .active-list {
            padding: 0 15px;

            .tournament-card {
              background: $mycontent-card;
              border-radius: $radius;
              font-size: $font-size - 2;
              margin-bottom: 25px;
              overflow: hidden;

              &:last-child {
                margin-bottom: 0;
              }

              .img-fluid {
                border-radius: $radius;
              }

              .info {
                align-items: center;
                display: flex;
                flex-direction: column;
                padding: 15px;

                .title {
                  font-size: $font-size - 2;
                  font-weight: bold;
                  margin-bottom: 15px;
                  text-transform: uppercase;
                }

                button {
                  font-size: $font-size - 2;
                }
              }

              .info-bottom {
                background: rgba(#000, .1);
                border-top: 1px solid $tertiary;
                display: none;
                padding: 5px 0;
                text-align: center;

                span {
                  font-size: $font-size - 3;
                  font-weight: bold;
                  opacity: .85;
                  text-transform: uppercase;
                }
              }
            }
          }
        }

        &.compressed {
          flex: 0 0 $right-sidebar-compressed;

          .new-card {
            .box-header {
              .box-title {
                md-icon {
                  @extend %compressed-classes;
                }
              }
            }
          }

          .my-content {

            .mat-tab-header,
            .subtitle {
              display: none;

              .mat-tab-body-content {
                padding: 15px 5px;
              }
            }

            .icon-bg {
              height: 65px;
              margin: 15px auto;
              width: 65px;
            }

            .sub-text {
              display: none !important;
            }
          }

          .games-list-container {
            .games-row {
              .col-game {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
              }
            }
          }
        }
      }

      .col-left,
      .col-right {
        .scrollbar {
          max-height: calc(100vh - 190px);
        }
      }

      .scrollable-col {
        max-height: calc(100vh - 150px);
        min-height: calc(100vh - 150px);
        overflow: hidden;
        transition: all .5s ease;

        @media #{$mat-lt-md} {
          max-height: calc(100vh - 162px);
          min-height: calc(100vh - 162px);
        }

        .new-card {
          background: $sidecol-background;
          margin: 0 !important;
        }
      }

      .footer-container {
        &.mobile {
          margin-bottom: 0;
        }
      }
    }
  }

  @include small-desktop {
    .header2 .home-casino-container .casino-wrapper .scrollable-col {
      max-height: calc(100vh - 160px);
      min-height: calc(100vh - 160px);
    }
  }
}
