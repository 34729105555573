@mixin language-selector-dialog($theme) {

  .language-selector-container {
    .flag-container {
      margin: 15px;

      .flag {
        transform: scale(2);
      }
    }
  }
}
