// @deprecated
@mixin sidebar($theme) {
  $primary-color: map-get($map: $theme, $key: primary);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $background: map-get($map: $theme, $key: background);
  $categories-img-dir: '/assets/images/casino/categories';
  $is-dark: map-get($map: $theme, $key: is-dark);
  $top-box-unlogged-background: if($is-dark, lighten($background, 5%), lighten($background, 100%));
  $menu-item-background: if($is-dark, lighten($background, 2%), lighten($background, 3%));
  $border-color: map-get($map: $theme, $key: border-color);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $font-size: map-get($map: $theme, $key: font-size);
  $top-box-height: 60px;
  $radius: map-get($map: $theme, $key: radius);

  .mat-drawer {
    background: $background;
    height: calc(100% - 60px);
    margin-top: 60px;
    width: 300px;
    z-index: 11 !important;

    .sidebar-container {
      .sidebar-flag-icon {
        height: 18px;
        width: 18px;
      }

      .max-vh {
        max-height: 100vh;
      }

      .top-box {
        background: $top-box-unlogged-background;
        color: $text-color;
        height: $top-box-height;
        z-index: 10;

        .actions-container {
          display: flex;
          flex-wrap: nowrap;
          list-style: none;
          margin-right: 5px;

          li {
            background: $menu-item-background;
            border-radius: $radius;
            display: inline;
            font-size: 20px;
            margin-right: 5px;
            opacity: .8;
            padding: 5px;

            &.active-menu {
              background: darken($menu-item-background, 4%);
            }
          }
        }

        .account-info-container {
          margin-left: 15px;

          .username-container {
            font-weight: bold;
            width: 100%;
          }

          .balance-container {
            width: 100%;
          }
        }
      }

      .menu-container {
        .mat-mdc-tab-label {
          flex: 1 1 auto;
        }

        .mat-mdc-nav-list {
          padding-top: 0;

          .menu-item-languages {
            background: $menu-item-background !important;
            border-bottom: 1px solid $border-color;
            height: 40px;
          }

          .mat-mdc-list-item {
            &.menu-item {
              background: $menu-item-background;
              border-bottom: 1px solid $border-color;
              height: 40px;
            }

            .icon {
              float: left;
              margin-right: 10px;

              &.external {
                background-repeat: no-repeat;
                height: 16px;
                width: 20px;

                &.external {
                  background-repeat: no-repeat;
                  height: 16px;
                  width: 20px;
                }

                &.live-casino {
                  background-image: url('#{$categories-img-dir}/live-casino.svg');
                }

                &.casino {
                  background-image: url('#{$categories-img-dir}/video-slots.svg');
                }

                &.virtual {
                  background-image: url('#{$categories-img-dir}/virtual-games.svg');
                }

                &.lucky6 {
                  background-image: url('#{$categories-img-dir}/video-bingo.svg');
                }
              }
            }

            &:hover,
            &.active {
              background: $primary-color !important;
              border-radius: 0;

              a {
                color: $text-on-primary;
              }
            }

            a {
              align-items: center;
              color: $text-color;
              display: flex;
              font-size: 14px;
              height: 100%;
              line-height: 1;
              margin-bottom: 0;
              margin-left: -15px;
              padding-left: 15px;
              width: 100%;

              md-icon {
                font-size: $font-size + 5;
                margin-right: 10px;

                span {
                  display: block;
                }
              }
            }
          }

          menu {
            > ul > li .has-sub a {
              color: $tertiary;
              font-size: $font-size;
              font-weight: 700;
              pointer-events: none;
              text-transform: uppercase;
            }

            .leaf {
              div {
                background: $menu-item-background;
                border-bottom: none;
              }

              .active {
                background: $primary-color;
                color: $text-on-primary;
              }

              a {
                font-size: $font-size + 1;
              }
            }

            a {
              color: $text-color;
              opacity: 1;
              padding: 0 16px;

              md-icon {
                display: none;
                font-size: $font-size + 5;
                margin-right: 10px;
              }

              span {
                line-height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .sport {
            &::before {
              border-radius: 0 3px 3px 0;
              bottom: 0;
              content: '';
              display: inline-block;
              height: 20px;
              left: 0;
              margin: auto;
              position: absolute;
              top: 0;
              width: 4px;
            }

            //@each $sport, $color in $sport-colors {
            //  &.sport-#{$sport}::before {
            //    background-color: $color;
            //  }
            //}
          }
        }
      }
    }
  }
}
