@mixin casino-tournament($theme) {
  $is-dark: map-get($theme, is-dark);
  $background: map-get($theme, background);
  $border-color: map-get($theme, border-color);
  $font-size: map-get($theme, font-size);
  $radius: map-get($theme, radius);
  $success: map-get($theme, success);
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $text-color: map-get($theme, text-color);
  $text-on-primary: map-get($theme, text-on-primary);
  $default-content-background: if($is-dark, lighten($background, 5%), $text-on-primary);
  $border: if($is-dark, rgb(255, 255, 255, .1), $border-color);

  casino-tournament {
    display: block;

    .casino-tournament-wrapper {
      .casino-tournament-img {
        border-radius: $radius;
      }

      .mat-tab-label-content {
        color: $text-color;
      }

      .mat-tab-body {
        &-content {
          background: $background;

          .mat-tab-body-content {
            background: transparent;
          }

          .join-btn {
            background-color: $secondary;
            color: $primary;
            &.joined {
              opacity: .5;
            }
          }

          .no-result-wrapper {
            background-color: $default-content-background;

            .icon {
              font-size: 40px;
            }
          }

          .mat-accordion {
            .mat-expansion-panel {
              &-header {
                background-color: transparent;
                border: none;

                &-title {
                  h3 {
                    color: $text-color;

                    @media #{$mat-lt-xs} {
                      font-size: $font-size;
                    }
                  }

                  .badge {
                    background: $success;
                    border-radius: 4px;
                    color: $text-on-primary;
                    font-size: 12px;
                    font-weight: 700;
                    padding: 4px 6px;
                    width: auto;
                  }
                }
              }

              &-body {

                .cat-title {
                  color: $text-color;
                  font-size: 18px;
                }

                .countdown-remaining {
                  h4 {
                    display: none;
                  }

                  .cell {
                    display: flex;
                    font-weight: 700;
                    margin-right: 4px;

                    .value {
                      div:first-child {
                        display: flex;
                      }
                    }
                  }
                }

                @media #{$mat-lt-sm} {
                  padding: 0 8px 8px;
                }
              }

              &-content {
                .terms-btn {
                  background: transparent;
                  color: lighten(grey, 5%);
                  font-size: 14px;
                  font-weight: 500;
                  text-decoration: underline;
                  text-transform: capitalize;
                }

                .bordered {
                  border: 1px solid $border;
                  border-radius: $radius;
                }

                .mat-tab-body-content {
                  padding: 10px;

                  table {
                    tr {
                      &:not(:last-child) {
                        border-bottom: 1px solid  $border;
                      }

                      th.text-start {
                        padding: 5px 10px;
                        text-align: start;
                      }

                      td {
                        padding: 7px 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  tournament-terms-dialog{
    .mat-card{
      background: transparent;

      &-title{
        background-color: transparent;

        .close-icon{
          display: none;
        }

        .title{
          color: $text-color;
          font-size: 16px;
          font-weight: 400;
          text-transform: capitalize;
        }
      }

      .terms-paragraf{
        letter-spacing: 0.44px;
        line-height: 20px;
        max-width: 680px;
        text-align: start;

        @media #{$mat-lt-md} {
          max-width: 100%;
        }
      }
    }
  }
}
