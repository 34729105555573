@mixin promotion-details($theme) {
  $is-dark: map-get($theme, is-dark);
  $header: map-get($theme, header);
  $font-size: map-get($theme, font-size);
  $accordion: if($is-dark, lighten($header, 5%), darken($header, 5%));

  .promotion-details {
    .mat-flat-button{
      @media #{$mat-lt-md} {
        width: 100% ;
      }
    }

    .promo-date-container {
      word-break: break-all;
    }

    .promotion-accordion {
      .mat-expansion-panel {
        background: $accordion;
      }
    }

    .promo-date {
      font-size: $font-size - 1;
      padding: 5px 15px;

      &-started {
        background-color: rgba(40, 211, 88, .2);
        border: 1px solid #25AE60;
      }

      &-activated {
        background-color: rgba(255, 193, 7, .2);
        border: 1px solid #FFC107;
      }

      &-ended {
        background-color: rgba(231, 0, 32, .2);
        border: 1px solid #E70020;
      }
    }

    .promo-text {
      font-size: $font-size + 1;
      line-height: 1.67;
    }

    .mat-expansion-panel-header-title {
      justify-content: space-between;
    }

    .padlock-container div {
      display: inline-block;
    }

    .margin-screen {
      min-height: 450px;
    }
  }
}
