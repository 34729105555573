@mixin bottom-bar($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $font-size: map-get($map: $theme, $key: font-size);
  $bar-background: if($is-dark, lighten($background, 13%), lighten($background, 3%));
  $bar-height: 58px;

  bottom-bar {
    bottom: 0;
    height: $bar-height;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1;

    .mat-button-base {
      .md-icon {
        font-size: $font-size + 7;
      }
    }

    .col {
      background-color: $bar-background;
      z-index: 2;

      &.center-container {
        background-color: transparent;
        z-index: 1;

        svg {
          fill: $bar-background;
        }
      }
    }

    &.column {
      .col {

        .mat-button-wrapper {
          align-items: center;
          flex-direction: column;
        }

        .item-label {
          font-size: $font-size - 4;
        }
      }
    }

    a.mat-badge .mat-badge-content {
      border-radius: 15%;
      width: 30px;
    }
  }
}
