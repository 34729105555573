@mixin hero-banner($theme) {
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $font-size: map-get($map: $theme, $key: font-size);

  .hero-banner {
    position: relative;

    > img {
      height: 350px;
      object-fit: cover;
      object-position: center;

      @media #{$mat-lt-sm} {
        height: 250px;
      }
    }

    .overlay {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .container {
        color: $text-on-primary;
        position: relative;

        .caption {
          align-items: flex-start;
          display: flex;
          flex-direction: column;

          h3 {
            font-size: $font-size + 9;
            margin-bottom: 15px;
          }

          p {
            font-size: $font-size + 3;
            margin-bottom: 30px;
          }

          h3,
          p {
            background: rgba(#000, .3);
            display: inline;
            font-weight: bold;
            line-height: 1.5;
            padding: 5px 10px;
          }

          @media #{$mat-lt-sm} {
            align-items: center;
            text-align: center;

            h3,
            p {
              background: rgba(#000, .5);
            }
          }
        }
      }

      mat-form-field {
        width: 100%;
      }
    }
  }

}
