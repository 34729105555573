@mixin common-search($theme) {
  $danger: map-get($theme, danger);
  $success: map-get($theme, success);

  common-search {
    &.invalid {
      .mat-form-field-outline {
        color: $danger !important;
      }
    }

    &.valid {
      .mat-form-field-outline {
        color: $success !important;
      }
    }
  }
}
