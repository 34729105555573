@mixin page-browse-casino($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $filter-background: if($is-dark, lighten($background, 5%), darken($background, 5%));
  $item-active-color: map-get($map: $theme, $key: tertiary);

  .browse-casino-container {

    &.mobile {
      .mat-form-field-infix {
        width: 150px;
      }
    }

    .filters-container {
      background: $filter-background;
      margin-top: 2px;
    }

    .loading-container {
      height: 200px;
    }

    h4 {
      color: $item-active-color;
      padding: 30px;
    }

  }
}
