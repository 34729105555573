@mixin swiperjs($theme) {
  $is-dark: map-get($theme, is-dark);
  $background: map-get($theme, background);
  $button-width: 40px;
  $font-size: map-get($theme, font-size);
  $tertiary: map-get($theme, tertiary);
  $text-color: map-get($theme, text-color);
  $button-color: if($is-dark, lighten($background, 10%), lighten($background, 2%));

  .swiper {
    .swiper-wrapper {
      justify-content: center;
    }
    &-button-prev {
      left: 0;
    }

    &-button-next {
      right: 0;
    }

    &-button-next,
    &-button-prev {
      background-color: $button-color;
      bottom: 0;
      width: calc($button-width - 10px);

      &:after {
        color: $text-color;
        font-size: $font-size - 1;
        font-weight: bold;
      }
    }

    .title {
      color: $tertiary;
      font-size: $font-size + 3;

      @include tablet {
        font-size: $font-size + 9;
      }

      @include mobile {
        font-size: $font-size + 1 !important;
      }
    }
  }
}
