@mixin bonuses($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $tab-content-background: if($is-dark, lighten($background, 5%), $text-on-primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $font-size: map-get($map: $theme, $key: font-size);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $success: map-get($map: $theme, $key: success);
  $second-row-background: map-get($map: $theme, $key: header);
  $third-row-background: if($is-dark, lighten($second-row-background, 15%), #F9F9F9);
  $border-color: map-get($theme, border-color);

  .bonuses-container {
    .sportbook-content {
      background: $tab-content-background;
    }
  }

  wallets-viewer {
    .wallets-wrapper {
      margin-top: 5px;
    }
  }

  .wallets-bonuses {
    .mat-card {
      .mat-card-title {
        .box-title {
          .available {
            color: $text-color;
            font-weight: 500;

            span {
              color: $text-color;
            }
          }
        }
      }

      .no-bonus {
        &-icon {
          @include dark-light-icon(50);
        }

        &-text {
          font-size: $font-size;
        }
      }

      .mat-card-content {
        font-size: $font-size - 1;

        .mat-tab-header {
          border-bottom: 1px solid $border-color !important;
        }

        .mat-card-row {
          border-bottom: .5px solid $border-color;
        }

        .progress-bar-container {
          color: $text-color;
          font-size: $font-size - 1;

          mat-progress-bar {
            border-radius: 3px;
            color: $success;
            height: 6.5px;
          }
        }

        .card-item-box {
          background: $third-row-background;
          border-radius: 4px;
        }

        .card-item-title {
          color: $tertiary;
          font-weight: 500;

          .progress-title {
            font-size: $font-size - 3;
          }
        }

        .mat-tab-group {
          .mat-tab-list {
            .mat-tab-label {
              background: transparent;
              font-size: $font-size - 1;

              .mat-tab-label-content {
                color: $text-color;
                font-weight: 600;
              }
            }
          }
        }

        .bonus-paginator {
          .mat-paginator {
            &-container {
              justify-content: center;
            }

            :is(&-page-size, &-range-label) {
              display: none;
            }
          }
        }
      }
    }
  }
}
