@use 'utilities/utilities';

@mixin requirements-table($theme) {
  $header: map-get($map: $theme, $key: header);
  $font-size: map-get($map: $theme, $key: font-size);

  table.requirements {
    font-size: $font-size;

    .promotions-buttons {
      > div {
        width: 230px;

        button {
          font-size: $font-size - 1;
          padding: 8px;
          width: 100%;

          &:not(:last-child) {
            margin-right: 6px;
          }
        }
      }
    }

    @include mobile {
      .promotions-buttons {
        display: flex;
        height: auto;
        justify-content: flex-end;
      }
    }

    @include tablet {
      tr:not(.tablet-only) {
        display: table-row;
      }

      td {
        display: table-cell;
      }
    }

    tr:not(.tablet-only) {
      > :first-child {
        background-color: $header;

        @include tablet {
          background-color: unset;
        }
      }
    }
  }
}
