@mixin footer-full-width($theme) {
  @media only screen and (min-width: 1024px) {
    .full-width-footer {
      main,
      agent-login-page {
        footer {
          display: none;
        }
      }
    }
  }
}
