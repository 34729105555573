@mixin new-container($theme) {
  $max-width: map-get($theme, max-width);
  $gray-text: map-get($map: $theme, $key: gray-text);

  @include tablet {
    .new-container {
      margin: 0 auto;
      max-width: $max-width;
      padding-left: 15px;
      padding-right: 15px;

      .left-column {
        .mat-card {
          margin-left: 0;
        }

        .search-events-dropdown-container {
          padding-left: 0;
        }
      }

      .right-column {
        .mat-card {
          margin-right: 0;
        }
      }

      &.header4 {
        .max-vh,
        .home-casino-container .casino-wrapper .scrollable-col{
          max-height: calc(100vh - 120px);
        }

        .content {
          min-height: calc(100vh - 120px);
        }

        .overview-container .max-vh {
          max-height: calc(100vh - 285px);
        }

        .live-event-container:not(.mobile) .max-vh {
          max-height: calc(100vh - 162px);
        }
      }

      &.header3 {
        @include header-3-mid-height();
      }
    }

    .header-3-items + .header3 {
      @include header-3-mid-height(40px);
    }
  }
}

@mixin header-3-mid-height($height: 0px) {
  .max-vh,
  .home-casino-container .casino-wrapper .scrollable-col{
    max-height: calc(100vh - (150px + $height));
  }

  .content {
    min-height: calc(100vh - (150px + $height));
  }

  .overview-container .max-vh {
    max-height: calc(100vh - (315px + $height));
  }

  .live-event-container:not(.mobile) .max-vh {
    max-height: calc(100vh - (192px + $height));
  }
}
