@mixin page-poker($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $font-size: map-get($map: $theme, $key: font-size);
  $border-color: map-get($map: $theme, $key: border-color);
  $base-img-dir: '/assets/images';
  $caption-font-size: 14px;
  $bottombar-height: 265;
  $bottom-bg: if($is-dark, lighten($background, 7%), darken($background, 0%));
  $bg-gradient-poker: if($is-dark, linear-gradient(180deg, rgba(40, 40, 40, 0.75) 0%, $background 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, #FAFAFA 100%));
  $default-content-background: if($is-dark, lighten($background, 5%), lighten($background, 100%));

  .poker-shortcut {
    padding: 30px 0;

    @media #{$mat-lt-sm} {
      padding: 20px 0;
    }

    .info-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .mat-card-content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-self: center;
        flex-grow: 1;

        .form-row {
          border-bottom: 1px solid $border-color;
          width: 100%;
          transition: all .2s ease;
          opacity: .8;
          padding: 15px 0;
          color: $text-on-primary;


          &:hover {
            opacity: 1;
          }

          &:last-child {
            border-bottom: none;
          }

          @media #{$mat-lt-sm} {
            opacity: 1;
          }

          .apple-bg,
          .windows-bg,
          .android-bg {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            flex: 0 0 75px;
            height: 40px;
            width: 40px;
          }

          .apple-bg {
            $img-url: if($is-dark, '#{$base-img-dir}/icons/apple.svg', '#{$base-img-dir}/icons/apple-black.svg');
            background-image: url($img-url);
          }

          .android-bg {
            $img-url: if($is-dark, '#{$base-img-dir}/icons/android.svg', '#{$base-img-dir}/icons/android-black.svg');
            background-image: url($img-url);
          }

          .windows-bg {
            $img-url: if($is-dark, '#{$base-img-dir}/icons/windows.svg', '#{$base-img-dir}/icons/windows-black.svg');
            background-image: url($img-url);
          }
        }
      }
    }

    .poker-box.mat-card.new-card.flat-card {
      height: 100%;

      .mat-card-content {
        height: 100%;
        padding-bottom: 40px;

        @media #{$mat-lt-sm} {
          height: auto;
          padding: 0;
        }

        .form-row {
          height: 100%;

          @media #{$mat-lt-sm} {
            height: auto;
          }
        }
      }

      .img-box {
        flex: 0 0 45%;
        height: 100%;
        overflow: hidden;

        .img-fluid {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          display: flex;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        @media #{$mat-lt-sm} {
          flex: 0 0 100%;
          height: 250px;
        }
      }

      .right-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .txt-box {
          padding: 20px 20px 0 20px;
        }

        .anchor-box {
          padding: 0 20px 10px 20px;

          @media #{$mat-lt-sm} {
            padding: 20px;
          }
        }

        .btn-box {
          .mat-flat-button {
            width: 100%;
            height: 48px;
            font-size: $font-size + 2;
            border-radius: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;

            md-icon {
              font-size: $font-size + 4;
            }
          }
        }
      }

      &.extended {
        .img-box {
          flex: 0 0 30%;
          min-height: 175px;

          @media #{$mat-lt-sm} {
            flex: 0 0 100%;
          }
        }
      }
    }
  }

  .poker-container {
    color: $text-on-primary;

    .fullscreen-hero {
      background: $bg-gradient-poker, url('/assets/images/poker/poker-bg.webp') no-repeat top;
      position: relative;
      max-height: calc(100vh - 150px);
      min-height: calc(100vh - 150px);
      overflow: hidden;

      @media #{$mat-lt-md} {
        max-height: calc(100vh - 100px);
        min-height: calc(100vh - 100px);
      }

      .img-hero {
        @extend .fullscreen-hero;
        object-fit: cover;
        width: 100%;
      }

      .overlay {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: calc(100% - #{$bottombar-height}px);
        justify-content: center;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;

        @media #{$mat-lt-sm} {
          height: 100%;
        }

        .text-container {
          line-height: 1.85;

          .col-overlay {
            padding: 0 40px;

            @media #{$mat-lt-sm} {
              padding: 0 15px;
            }

            .title {
              color: $text-color;
              font-size: $font-size + 19;
              margin-bottom: 10px;
              word-break: break-word;

              @media #{$mat-lt-sm} {
                font-size: $font-size + 11;
              }
            }

            .caption {
              color: $text-color;
              font-size: $caption-font-size;
              margin-bottom: 30px;

              @media #{$mat-lt-sm} {
                font-size: $font-size;
              }
            }

            .mat-flat-button {
              font-size: $caption-font-size;
              margin-bottom: 30px;
              padding: 10px 30px;

              @media #{$mat-lt-sm} {
                font-size: $font-size;
              }
            }
          }
        }

        .bottom-container {
          margin-top: auto;
        }
      }
    }

    .poker-bottom-bar {
      margin-top: -#{$bottombar-height}px;
      height: #{$bottombar-height}px;
      position: relative;
      text-align: center;
      line-height: 1.85;

      @include mobile {
        background: $bg-gradient-poker;
      }

      @media #{$mat-lt-sm} {
        margin-top: 0;
        height: auto;
        padding: 35px 0;
        background: $bottom-bg;
      }

      .row {
        height: 100%;

        .col-bottom {

          @media #{$mat-lt-sm} {
            margin-bottom: 35px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .poker-card {
        background: $default-content-background;
        color: $text-color;

        &-img {
          width: 100%;
        }

        &-content {
          padding: 10px 14px;
        }

        &-title {
          color: $text-color;
          font-size: $font-size + 1;
          line-height: 1;
        }

        &-description {
          display: -webkit-box;
          font-size: $font-size;
          line-height: 18px;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }
  }

  .evolution-lobby {
    .poker-container {
      .fullscreen-hero {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .overlay {
        height: 100%;
        position: relative;

        .title {
          color: $text-color !important;
          text-shadow: none;
        }
      }

      .poker-bottom-bar {
        height: auto;
        margin-top: 0;
      }
    }
  }

  @media #{$mat-lt-md} {
    .evolution-lobby {
      .poker-container {
        .fullscreen-hero {
          max-height: 100%;
          min-height: auto;
        }

        .overlay {
          margin-block: 20px;
        }
      }
      .poker-bottom-bar {
        background: transparent;
        max-width: 540px;

        .col-bottom {
          margin-bottom: 20px;
        }
      }
    }
  }

  @include small-desktop {
    .evolution-lobby {
      .poker-container {
        .fullscreen-hero {
          justify-content: space-evenly;
        }
      }
    }
  }
}
