* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: inherit;
  }

  &:focus {
    outline: none;
  }
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;

  &.cdk-global-scrollblock {
    overflow-y: scroll;
    position: fixed;
    width: 100%;
  }

}

menu {
  margin: 0;
  padding: 0;
}

// Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
