@mixin time-bar($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $item-active-background: if($is-dark, lighten($background, 3%), lighten($background, 100%));

  .time-bar-container {
    .mat-tab-label {
      flex: 1 1 auto;
      min-width: 1px !important;
      padding: 0 12px !important;

      // &.mat-tab-label-active {
      //   background-color: $item-active-background;
      // }
    }
  }
}
