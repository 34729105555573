@mixin numeric-input($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $font-size: map-get($map: $theme, $key: font-size);
  $text-color: map-get($map: $theme, $key: text-color);
  $button-background: if($is-dark, lighten($background, 2%), darken($background, 2%));
  $border-color: map-get($map: $theme, $key: border-color);
  $primary-color: map-get($map: $theme, $key: primary);
  $radius: map-get($map: $theme, $key: radius);

  .numeric-cont {

    &.open {
      margin-top: -25px;

      .custom-input {
        margin-bottom: -3px;
        margin-top: 3px;
      }
    }

    .custom-input {
      border: 1px solid #808080;
      border-radius: $radius;
      float: right;
      line-height: 26px;
      padding: 0 5px;
      width: 110px;

      span {
        &.valuta {
          float: left;
          font-size: $font-size + 3;
          font-weight: bold;
          width: 10px;
        }
      }

      a {
        &.amount {
          float: right;
          font-weight: bold;
          height: 26px;
          text-align: right;
          width: calc(100% - 13px);
          overflow-x: auto;

          &.active {
            outline: none;
          }
        }
      }
    }

    .amount-btn-cont {
      float: left;
      margin-bottom: 10px;
      margin-left: -5px;
      margin-top: 10px;
      position: relative;
      width: calc(100% + 10px);
      z-index: 9;

      .amounts {
        float: left;
        width: 70%;

        a,
        button {
          border: none;

          &.btn {
            background-color: $button-background;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
            border-radius: 0;
            color: $text-color;
            display: table;
            float: left;
            font-size: $font-size - 1;
            height: 35px;
            margin: 0;
            outline: none;
            overflow: hidden;
            width: 33.33%;

            &::after {
              border-radius: 100%;
              content: '';
              height: 5px;
              left: 50%;
              opacity: 0;
              position: absolute;
              top: 50%;
              transform: scale(1, 1) translate(-50%);
              transform-origin: 50% 50%;
              width: 5px;
            }

            &.plus,
            &.minus {
              svg {
                vertical-align: middle;
                width: 18px;

                path {
                  fill: $primary-color;
                }
              }

              i {
                font-size: $font-size;
              }
            }
          }
        }
      }

      .actions {
        float: left;
        margin-left: -1px;
        width: 30%;

        button {
          background-color: $button-background;
          border: none;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
          border-radius: 0;
          color: $primary-color;
          display: table;
          float: left;
          font-size: $font-size - 1;
          font-weight: bold;
          height: 35px;
          margin: 0;
          outline: none;
          overflow: hidden;
          width: 100%;

          svg {
            vertical-align: middle;
            width: 18px;

            path {
              fill: $primary-color;
            }
          }

          &::after {
            border-radius: 100%;
            content: '';
            height: 5px;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: scale(1, 1) translate(-50%);
            transform-origin: 50% 50%;
            width: 5px;
          }
        }
      }
    }
  }

  .transfer-wallet,
  .deposit-withdraw {
    .numeric-cont {
      .custom-input {
        float: left;
        margin-left: 50%;
        transform: translateX(-50%);
        width: 50%;
      }

      .amount-btn-cont {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}
