@mixin page-casino-live($theme) {
  $primary: map-get($map: $theme, $key: primary);
  $font-size: map-get($map: $theme, $key: font-size);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);

  .live-casino-container {

    &.mobile {
      .carousel-slider-container {
        .swiper-wrapper {
          height: 400px;
        }
      }

      .slider-container {
        height: 400px;
      }
    }

    &:not(.mobile) {
      .carousel-slider-container {
        .swiper-wrapper {
          height: 450px;
        }
      }

      .slider-container {
        height: 450px;
      }
    }

    .slider-container {
      .swiper-container {
        .swiper-pagination-bullet {
          height: 10px;
          width: 10px;

          &.swiper-pagination-bullet-active {
            background: $primary;
          }
        }

        .slide-container {
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 100%;

          .slide-content-container {
            margin: 0 10%;
            width: 100%;

            .slide-title {
              color: $text-on-primary;
              font-weight: bold;
              margin-bottom: 0;
            }

            .slide-content {
              color: $text-on-primary;
              font-size: $font-size + 2;
              padding: 10px 0;
            }

            a {
              line-height: 45px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
