@mixin content-page($theme) {
    .content-page {
        .content-container { 
            max-width: 1300px;
            margin-inline: auto;

            perfect-scrollbar{
                @media (max-width: 992px){
                    max-height: unset;
                }
            }
        }

        .mat-expansion-panel{
            border-radius: 0 !important;

            &-header{
                height: 38px;
            }

            &-body {
                padding: 0;
            }
        }
    }
}