@mixin expansion-panel($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $font-size: map-get($map: $theme, $key: font-size);
  $radius: map-get($map: $theme, $key: radius);
  $default-panel-background: if($is-dark, lighten($background, 8%), lighten($background, 100%));
  $shadow-small: if($is-dark, 0 2px 2px 1px rgba(#000, .45), 0 2px 2px 1px rgba(#000, .2));
  $panel-header-height: 35px;

  .mat-expansion-panel {
    background: $default-panel-background;
    border-radius: $radius;
    box-shadow: $shadow-small;

    .mat-expansion-panel-header {
      font-size: $font-size;
      height: $panel-header-height;
      padding: 0 15px;
      text-transform: uppercase;

      .mat-content {
        align-items: center;
      }

      &.mat-expanded {

        .mat-expansion-indicator {
          align-items: center;
          display: flex;
        }
      }
    }

    &.no-padding {

      .mat-expansion-panel-header {
        font-size: $font-size;
        height: 35px;
        line-height: 1;
        padding: 0 10px;
      }

      .mat-expansion-panel-body {
        padding: 0 !important;

      }
    }

    &.no-radius {
      border-radius: 0 !important;
    }
  }

}
