* {
  scrollbar-color: gray transparent;

  &::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 3px 0;
  }

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border: 1px solid lightgray;
    border-radius: 5px;

    &:hover {
      background-color: map-get($dark-theme, 'primary');
      border: 5px solid map-get($dark-theme, 'primary');
    }
  }

}

.scrollbar {
  overflow: auto;

  &.y {

  }

  &.disabled {
    overflow: hidden;
  }
}

/*@mixin browser-scrollbar($track-bg: #DDD, $scrollbar-thumb: #7F7F7F, $hover: #555) {
  &::-webkit-scrollbar-track {
    background-color: $track-bg;
  }

  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb;
    border: 2px solid $track-bg;
    border-radius: 8px;

    &:hover {
      background-color: $hover;
    }
  }
}

@if $is-dark {
        @include browser-scrollbar(#353535);
      } @else {
        @include browser-scrollbar();
      }
*/
