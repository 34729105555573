@mixin casino-filters($theme) {
  $background: map-get($theme, background);
  $font-size: map-get($theme, font-size);
  $radius: map-get($theme, radius);

  casino-filters {
    @include small-desktop {
      .mat-mdc-dialog-container {
        width: 450px;
      }

      .dialog {
        margin: 10px 0;
      }
    }

    .dialog {
      .mat-mdc-card {
        &-title {
          .close-icon {
            margin-left: 15px;
          }

          .title {
            font-size: $font-size;
          }
        }

        &-content {
          padding: 10px !important;

          .mat-checkbox + span {
            margin-left: 5px !important;
          }
        }
      }

      .mat-expansion-panel {

        &.mt-4 {
          margin-top: 0 !important;
        }

        &.mat-expanded {
          .mat-expansion-panel-header {
            border-radius: $radius $radius 0 0;
            height: 40px;
          }
        }

        &-header {
          height: 40px;

          .mat-content {
            font-size: $font-size;
            font-weight: 700;
            text-transform: capitalize;

            span:first-child {
              margin-right: 5px;
            }
          }
        }

        &-content {
          border: 1px solid $background;
          border-radius: 0 0 $radius $radius;
          max-height: 200px;
          overflow-y: auto;

          /*&::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #AAA;
            border: none;
            border-radius: 8px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: #999;
          }*/

          @include tablet {
            max-height: 300px;
          }
        }

        &-body {
          .mat-checkbox + span,
          .mat-checkbox + div {
            font-size: $font-size;
            font-weight: 700;
          }
        }
      }

      .providers-panel {
        img {
          height: 35px;
          max-width: 80px;
        }
      }
    }
  }
}
