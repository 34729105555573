@mixin homepage-games($theme) {
  $background: map-get($map: $theme, $key: background);
  $primary-color: map-get($map: $theme, $key: primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $is-dark: map-get($map: $theme, $key: is-dark);
  $font-size: map-get($map: $theme, $key: font-size);
  $radius: map-get($map: $theme, $key: radius);

  .homepage-games-container {
    .cat-title {
      color: $text-color;
      font-size: $font-size + 7;
      margin: 0;
      padding-left: 20px;
      position: relative;

      @media #{$mat-lt-sm} {
        font-size: $font-size + 5;
      }

      &::before {
        background: $primary-color;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 5px;
      }
    }

    .games-category {
      .game-thumb {
        border-radius: $radius;
        display: flex;
        margin-bottom: 10px;
        overflow: hidden;
        position: relative;

        .img-fluid {
          display: block;
          width: 100%;
        }

        .overlay {
          align-items: center;
          background: rgba($background, .65);
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          left: 0;
          opacity: 0;
          padding: 0 10px;
          position: absolute;
          top: 0;
          transition: all .25s ease-in-out;
          width: 100%;

          @media #{$mat-lt-md} {
            background: transparent;
            opacity: 1;
          }

          .game-title {
            @include ellipsis;
            background: $text-color;
            bottom: 0;
            color: $background;
            display: block;
            font-size: $font-size + 1;
            font-weight: bold;
            height: 32px;
            left: 0;
            line-height: 32px;
            position: absolute;
            text-align: center;
            width: 100%;

            @media #{$mat-lt-md} {
              display: none;
            }
          }

          button {
            height: 34px;
            margin: 0 auto 32px;
            padding: 10px 0;
            width: 115px;

            @media #{$mat-lt-xl} {
              font-size: $font-size - 2;
              height: 30px;
              padding: 5px 0;
              width: 95px;
            }

            @media #{$mat-lt-md} {
              display: none;
            }
          }
        }

        &.big-thumb {
          bottom: 0;
          height: calc(100% - 10px);
          left: 5px;
          position: absolute;
          right: 0;
          top: 0;
          width: calc(100% - 10px);

          .img-fluid {
            object-fit: cover;
          }

          @media #{$mat-lt-sm} {
            bottom: unset;
            height: auto;
            left: unset;
            position: relative;
            right: unset;
            top: unset;
            width: 100%;
          }
        }

        &:hover {
          .overlay {
            opacity: 1;

            .game-title {
              transform: translateX(0);
            }

            button {
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
}
