@mixin animations($theme) {
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);

  .timerblink {
    animation: blinker 1.3s linear infinite;
  }

  @keyframes blinker {
    90% {
      opacity: 0;
    }
  }

  .highlightonscored {
    animation: highlight 1s linear;
  }

  @keyframes highlight {
    0% {
      background: #3BAF29;
      color: $text-on-primary;
    }

    100% {
      background: none;
      color: inherit;
    }
  }

  .flash {
    animation: flash 1s;
  }

  @keyframes flash {

    from,
    50%,
    to {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0;
    }
  }
}
