@mixin login-dialog($theme) {
  $background: map-get($map: $theme, $key: background);
  $font-size: map-get($map: $theme, $key: font-size);
  $text-color: map-get($map: $theme, $key: text-color);
  $border-color: map-get($map: $theme, $key: border-color);

  .login-wrapper {
    padding: 0 !important;

    .form-container {
      padding: 30px 0;
    }

    .login-container {
      display: flex;
      flex-wrap: nowrap;
      height: 100%;
      justify-content: center;
      overflow: hidden;

      @include mobile {
        mobile-prefix .number {
          width: calc(100vw - 174px);
        }
      }

      @media #{$mat-lt-md} {
        flex-wrap: wrap;
        height: 100%;
      }

      .login-form {
        align-items: center;
        display: flex;
        flex: 1 1 auto;
        overflow: hidden;
        //padding: 30px 15px;

        @media #{$mat-lt-md} {
          flex: 0 0 100%;
        }

        hr {
          border-color: $border-color;
        }

        .alert {
          //text-transform: uppercase;

          .md-icon {
            font-size: 16px;
            padding-right: 5px;
          }
        }

        .registration-text {

          a {
            font-weight: bold;
          }
        }

        .recover-pw {
          opacity: .8;

          &:hover {
            opacity: 1;
          }
        }

        .recover-pw,
        .remember-me {
          font-size: $font-size - 1px;
        }

        .btn-row {
          padding-top: 15px;

          .mat-flat-button {
            width: 50%;
          }

          @media #{$mat-lt-md} {
            padding-bottom: 15px;
            padding-top: 0;

            .mat-flat-button {
              width: 150px;
            }
          }
        }

        .field-password {
          padding-bottom: .5rem;
        }
      }

      .side-banner {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;

        .slider-container {
          height: 100%;
        }

        .img-fluid {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }

        @media #{$mat-lt-md} {
          height: 250px;
          margin: 0 auto;
          width: 250px;
        }
      }
    }

    .loading-container {
      align-self: center;
      display: flex;
      flex-direction: column;
      height: 318px !important;
      justify-content: center;

      p {
        font-size: 14px;
        font-weight: bold;
        margin-top: 25px;
        text-transform: uppercase;
      }

      .icon-container {
        font-size: 40px;
      }

    }

    &.forgot-pw {
      line-height: 1.5;
      max-width: 450px;
      padding: 30px 15px;
      width: 100%;
    }
  }

}
