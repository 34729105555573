@mixin page-virtuals($theme) {
  $font-size: map-get($map: $theme, $key: font-size);
  $is-dark: map-get($map: $theme, $key: is-dark);
  $img-dir: '/assets/images/virtual/icons';

  .virtual-container {
    .virtuals-grid {
      padding: 15px;

      .new-card {
        margin: 0 !important;
        position: relative;

        .box-anchor {
          display: flex;
          justify-content: center;
          overflow: hidden;
          position: relative;

          .game-icon {
            background-position: center;
            background-repeat: no-repeat;
            display: block;
            height: 100px;
            margin: 55px auto;
            opacity: .8;
            transform: scale(.95);
            transition: all .4s ease;
            width: 150px;

             //&.kiron {
             //  $img-url: if($is-dark, '#{$img-dir}/kiron-white.png', '#{$img-dir}/kiron-dark.png');
             //  background-image: url($img-url);
             //  background-size: contain;
             //}
             //
             //&.lucky-6 {
             //  $img-url: if($is-dark, '#{$img-dir}/lucky6-white.svg', '#{$img-dir}/lucky6-dark.svg');
             //  background-image: url($img-url);
             //}
             //
             //&.lucky-x {
             //  $img-url: if($is-dark, '#{$img-dir}/luckyx-white.svg', '#{$img-dir}/luckyx-dark.svg');
             //  background-image: url($img-url);
             //}

            &.greyhound {
              $img-url: if($is-dark, '#{$img-dir}/greyhound-white.svg', '#{$img-dir}/greyhound-dark.svg');
              background-image: url($img-url);
            }

            &.vgreyhound {
              $img-url: if($is-dark, '#{$img-dir}/vgreyhound-white.svg', '#{$img-dir}/vgreyhound-dark.svg');
              background-image: url($img-url);
            }

            &.vhorses {
              $img-url: if($is-dark, '#{$img-dir}/vhorses-white.svg', '#{$img-dir}/vhorses-dark.svg');
              background-image: url($img-url);
            }

             //&.nextsix {
             //  $img-url: if($is-dark, '#{$img-dir}/nextsix-white.svg', '#{$img-dir}/nextsix-dark.svg');
             //  background-image: url($img-url);
             //}
          }

          .play-now {
            bottom: 25%;
            display: block;
            left: 0;
            margin: auto;
            opacity: 0;
            padding: 10px 35px;
            position: absolute;
            right: 0;
            transition: all .5s ease;
            width: 110px;
          }
        }

        &:hover {
          .box-anchor {
            .game-icon {
              opacity: 1;
              transform: translateY(-25px) scale(1.05);
            }

            .play-now {
              bottom: 25px;
              opacity: 1;
            }
          }
        }

        @media #{$mat-lt-md} {
          .box-anchor {
            .game-icon {
              opacity: 1;
              transform: translateY(-25px) scale(1);
            }

            .play-now {
              bottom: 25px;
              opacity: 1;
            }
          }
        }

        @media #{$mat-lt-md} {
          .box-anchor {
            .game-icon {
              height: 100px;
              width: 100px;
            }
          }
        }
      }
    }
  }
}

.virtual-container {
  .virtuals-grid-new {
    padding: 30px 15px;

    .new-card {
      margin: 0 !important;
      position: relative;

      .box-anchor {
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;

        .img-fluid {
          opacity: .55;
          transition: all .5s ease;
          width: 100%;
        }

        .play-now {
          bottom: 15px;
          box-shadow: 3px 3px 5px 0 rgba(#000, .5);
          display: block;
          left: 0;
          margin: 0 auto;
          position: absolute;
          right: 0;
          width: 110px;
        }
      }

      &:hover {
        .box-anchor {
          .img-fluid {
            opacity: 1;
          }
        }
      }

      @media #{$mat-lt-md} {
        .box-anchor {
          .img-fluid {
            opacity: .85;
          }
        }
      }
    }
  }
}
