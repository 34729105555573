@use 'variables/theme-variables';

@mixin icon($code, $where: 'after', $font-family: 'fontAwesome') {
  @if ($where == 'before') {
    &::before {
      content: $code;
      font-family: $font-family;
      font-size: 18px;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-weight: 100;
      text-transform: none;
      @content;
    }
  }

  @if ($where == 'after') {
    &::after {
      content: $code;
      font-family: $font-family;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-weight: 100;
      text-transform: none;
      @content;
    }
  }

}

@mixin include-font($family: 'arial', $font-name: '', $weight: normal, $style: normal) {
  @font-face {
    @if ($font-name == '') {
      font-family: $family;
    }

    @if (not($font-name == '')) {
      font-family: $font-name;
    }

    font-display: swap;
    src: url('#{$assets-dir}fonts/#{$family}.woff2') format('woff2'),
    url('#{$assets-dir}fonts/#{$family}.woff') format('woff'),
    url('#{$assets-dir}fonts/#{$family}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin block-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin position-sticky($top: 0, $zIndex: 5) {
  position: sticky;
  top: $top;
  z-index: $zIndex;
}

@mixin new-header-menu($background-color) {
  ul {
    height: 100%;

    .nav-item {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > div {
        display: flex;
        height: 100%;
      }

      .nav-link {
        font-weight: 700;
        padding: 0 15px;
        opacity: 1;

        &:hover,
        &.active {
          background-color: $background-color;
        }
      }
    }
  }
}

@mixin dark-light-icon($fontSize: 90, $color: #808080) {
  color: $color;
  font-size: #{$fontSize}px;
  line-height: 1;
}

@mixin common-input($text-color, $radius) {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
    --mdc-outlined-text-field-caret-color: #{$text-color};
  }

  .mdc-text-field--outlined .mdc-notched-outline {
    --mdc-outlined-text-field-outline-width: 2px;

    .mdc-notched-outline {
      --mdc-outlined-text-field-error-outline-color: rgba(255, 255, 255, .2);

      &__leading {
        border-radius: $radius 0 0 $radius;
      }

      &__trailing {
        border-radius: 0 $radius $radius 0;
      }
    }
  }

  .mdc-text-field--focused {
    .mdc-notched-outline {
      --mdc-outlined-text-field-caret-color: $text-color;
      &__leading {
        border-color: var(--mdc-outlined-text-field-hover-outline-color) !important;
      }

      &__trailing {
        border-color: var(--mdc-outlined-text-field-hover-outline-color) !important;
      }
    }
  }
}
