@mixin page-crash-games($theme) {
  $background: map-get($map: $theme, $key: background);
  $is-dark: map-get($map: $theme, $key: is-dark);
  $border-color: if($is-dark, rgba(255, 255, 255, .2), rgba(0, 0, 0, .42));

  .crash-games-container {

    .categories-container {

      .mat-mdc-tab-labels .mdc-tab--active {
        background-color: transparent;
      }

      .mat-mdc-tab-header {
        border-bottom: 1px solid $border-color !important;
      }

      .games-lite {
        @include tablet {
          grid-template-columns: repeat(3, 1fr);
        }

        @include small-desktop {
          grid-template-columns: repeat(4, 1fr);
        }

        game-tile {
          .favourites-icon {
            display: none;
          }

          .game-name-grid {
            display: none;
          }

          .overlay {
            background: rgba($background, .8);
            height: 100%;
            opacity: 0;
            pointer-events: none;
            transition: opacity .2s;

            .provider-name {
              margin-top: 0 !important;
            }

            &-container {
              justify-content: space-evenly;

              .play-container {
                height: auto !important;
                position: static !important;

                .mat-button-base {

                  @include tablet {
                    &:nth-child(2n) {
                      margin-top: 26px;
                    }

                    &:not(:only-child):first-child {
                      margin-top: 0;
                    }

                    &:only-child {
                      margin-bottom: 60px;
                    }
                  }
                }
              }
            }
          }

          &:hover {
            .overlay {
              backdrop-filter: blur(6px);
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
      }
    }
  }
}
