@mixin page-casino-lite($theme) {
  $is-dark: map-get($theme, is-dark);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $font-size: map-get($theme, font-size);
  $category-active: rgba(#000, .1);
  $menu-background: if($is-dark, lighten($background, 15%), lighten($background, 5%));
  $text-on-primary: map-get($theme, text-on-primary);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $text-color: map-get($theme, text-color);
  $category-links: if($is-dark, $text-on-primary, $text-color);
  $badge-text: if($is-dark, $background, $text-on-primary);

  .casino-lite-container {
    .navigation-container {
      background: $menu-background;
      @include position-sticky(0, 7);

      &.sort {
        @include position-sticky(72px, 6);
      }

      &-menu {
        display: flex;
        list-style-type: none;

        li {
          padding: 8px 12px;
          transition: background .2s ease;

          button {
            background: transparent;
            border: none;
          }

          a,
          button {
            color: $category-links;

            .icon-category {
              align-items: center;
              display: flex;
              font-size: $font-size + 12;
              height: 40px;
              justify-content: center;
              margin: auto;

              .mat-badge-content {
                background: $category-links;
                color: $badge-text;
                top: -4px;
              }
            }

            .category-name {
              font-size: $font-size - 1;
              white-space: nowrap;
            }
          }

          &.active {
            background: $category-active;
            border-bottom: 2px solid $tertiary;

            a,
            button {
              color: $tertiary;

              .mat-badge-content {
                background: $tertiary;
                color: $background;
              }
            }

            svg{
              color: $tertiary;
            }
          }

          &:hover {
            background: $category-active;

            .category-name,
            svg{
              color: $tertiary;
            }
          }

          svg{
            color: $text-color;
          }
        }
      }
    }

    .search-box {
      background-color: $background;
      padding-top: 32px;
      @include position-sticky(70px);
    }

    .container-games,
    .search-box {
      margin: auto;
      max-width: 1440px !important;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    .container-games {

      .no-games {
        background: $menu-background;
        border-radius: 2px;
        max-width: 650px;
        margin: 0 auto;
        padding: 24px;
        text-align: center;
      }

      .cat-title {
        font-size: $font-size + 7;
        font-weight: bold;
      }

      .favourite-empty {
        height: calc(100vh - 400px);
        justify-content: center;

        .icon-bg {
          background: url('/assets/images/add-favourites.svg') no-repeat center;
          background-size: contain;
          filter: contrast(0);
          height: 66px;
          margin: 0 0 20px;
          width: 66px;
        }
      }

      .brand-box {
        align-items: center;
        background-color: $category-active;
        border: 2px solid transparent;
        display: flex;
        flex-direction: column;
        padding: 1em;
        transition: .2s ease;

        .brand-logos {
          height: 110px;
          margin-inline: auto;
          max-width: 80%;

          img {
            height: 100%;
            object-fit: contain;
            width: 100%;
          }
        }

        .brand-name {
          color: $text-color;
          margin-top: 1em;
        }

        &:hover {
          border: 2px solid $primary;
        }
      }
    }

    @include tablet {
      .navigation-container {
        &-menu {
          justify-content: center;
        }
      }

      .container-games {

        .cat-title {
          font-size: $font-size + 9;
        }

        .games-counter {
          margin-left: 20px;
        }

        .favourite-empty {
          height: calc(100vh - 590px);
        }
      }
    }
  }
}
