@mixin page-balance($theme) {
  $background: map-get($map: $theme, $key: background);
  $border-color: map-get($map: $theme, $key: border-color);
  $primary-color: map-get($map: $theme, $key: primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $is-dark: map-get($map: $theme, $key: is-dark);
  $active-tile: if($is-dark, lighten($background, 0%), lighten($background, 3%));
  $tertiary: map-get($map: $theme, $key: tertiary);
  $font-size: map-get($map: $theme, $key: font-size);
  $shadow-small: if($is-dark, 0 2px 2px 1px rgba(#000, .45), 0 2px 2px 1px rgba(#000, .2));
  $sub-card-bg: if($is-dark, lighten($background, 10%), lighten($background, 100%));
  $radius: map-get($map: $theme, $key: radius);
  $secondary: map-get($theme, secondary);
  $text-on-secondary: map-get($map: $theme, $key: text-on-secondary);

  .payment-status-container {
    margin-top: 30px;
    max-width: 600px;

    @media #{$mat-lt-sm} {
      height: 100vh;
      margin: 0;
      padding: 0;
    }

    .flat-card {
      @media #{$mat-lt-sm} {
        height: 100%;
      }

      .status-wrapper {
        padding: 80px;

        @media #{$mat-lt-md} {
          padding: 50px;
        }

        @media #{$mat-lt-sm} {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: calc(100vh - 50px);
          justify-content: center;
          padding: 25px;
        }

        .icon-bg {
          &.success,
          &.declined {
            background: url('/assets/images/sad-face-icon.svg') no-repeat center;
            background-size: contain;
            display: flex;
            filter: contrast(0);
            height: 125px;
            margin: 0 auto 45px;
            width: 125px;
          }

          &.success {
            background: url('/assets/images/happy-icon.svg') no-repeat center;
          }

          .success,
          .declined {
            @media #{$mat-lt-sm} {
              height: 75px;
              width: 75px;
            }
          }
        }

        > .alert {
          line-height: 1.5;
          text-align: center;
        }
      }
    }
  }

  .balances-container {
    .payments-methods-container {
      padding: 20px;

      @media #{$mat-lt-sm} {
        padding: 15px;
      }

      .payments-methods-list {
        @media #{$mat-lt-sm} {
          align-items: baseline;
          display: flex;
          flex-direction: row;
        }

        .payments-method-container{
          border-bottom: 1px solid $border-color;
        }

        .new-card {
          box-shadow: $shadow-small;
          list-style: none;
          margin-bottom: 30px !important;

          &.active {
            background: rgba(#000, .1);
            border: 1px solid $primary-color;

            .heading {
              background: $primary-color;
              color: $text-on-primary;
            }
          }

          .mat-card-content {
            background: $sub-card-bg;

            .single-provider {
              background: transparent;
              border: none;
              border-bottom: 1px solid $border-color;
              display: block;
              overflow: hidden;
              padding: 10px 15px;
              text-overflow: ellipsis;
              text-transform: none;
              white-space: nowrap;
              width: 100%;

              &.active {
                background: $active-tile;
                color: $tertiary;
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }

          @media #{$mat-lt-sm} {
            margin: 0 20px 30px 0;
            width: 50%;


            &:last-child {
              margin: 0 0 30px;
            }

            .heading,
            .single-provider {
              font-size: $font-size;
            }
          }
        }
      }

      .payments-method-container {
        padding: 0 15px;

        .payments-method-info {
          .payments-method-name {
            color: $text-color;
            display: inline-block;
            font-size: 20px;
            margin-top: 0;
            position: relative;

            &::after {
              background: $primary-color;
              content: '';
              height: 3px;
              left: 0;
              position: absolute;
              top: 110%;
              width: 100%;
            }
          }

          .logo-container {
            padding-top: 15px;

            .card-cnt {
              align-items: center;
              background: $background;
              border: 1px solid $border-color;
              border-radius: $radius;
              display: flex;
              height: 52px;
              justify-content: center;
              margin-bottom: 15px;
              padding: 0 7px;
              min-width: 50px;

              @media #{$mat-lt-sm} {
                margin: 0 0 15px;
                padding: 8px 5px;
              }

              &:hover,
              &.active {
                border-color: $tertiary;
              }

              img {
                display: block;
                height: 100%;
                margin: 0 auto;
                max-height: 28px;
                max-width: 75px;
                object-fit: contain;
                width: auto;
              }

              a {
                align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;
              }
            }
          }

          p {
            font-size: 14px;
            padding: 10px 0;
          }

          .details-container {
            padding: 15px 0;
            border-bottom: 1px solid $border-color;

            h3 {
              color: $text-color;
              margin: 0;
              padding: 10px 0 20px;
            }
          }
        }

        .available-amount {
          color: $text-color;
          margin: 15px 0;

          .amount {
            color: $tertiary;
            margin-left: 5px;
          }
        }

        .provider-list {
          .new-card {
            box-shadow: $shadow-small;

            .mat-card-content {
              background: $sub-card-bg;

              .single-provider {
                background: transparent;
                border: none;
                border-bottom: 1px solid $border-color;
                padding: 10px 15px;

                &.active {
                  background: $active-tile;
                  color: $tertiary;
                }

                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }

  //.providers-container {
  //  padding: 30px;
  //
  //  @media #{$mat-lt-sm} {
  //    padding: 15px;
  //  }
  //
  //  .provider-list {
  //    @media #{$mat-lt-sm} {
  //      align-items: baseline;
  //      display: flex;
  //      flex-direction: row;
  //    }
  //
  //    .new-card {
  //      box-shadow: $shadow-small;
  //      list-style: none;
  //      margin-bottom: 30px !important;
  //
  //      &.active {
  //        background: rgba(#000, .1);
  //        border: 1px solid $primary-color;
  //
  //        .heading {
  //          background: $primary-color;
  //          color: $text-on-primary;
  //        }
  //      }
  //
  //      .mat-card-content {
  //        background: $sub-card-bg;
  //
  //        .single-provider {
  //          background: transparent;
  //          border: none;
  //          border-bottom: 1px solid $border-color;
  //          display: block;
  //          overflow: hidden;
  //          padding: 10px 15px;
  //          text-overflow: ellipsis;
  //          text-transform: none;
  //          white-space: nowrap;
  //          width: 100%;
  //
  //          &.active {
  //            background: $active-tile;
  //            color: $tertiary;
  //          }
  //
  //          &:last-child {
  //            border-bottom: none;
  //          }
  //
  //        }
  //      }
  //
  //      @media #{$mat-lt-sm} {
  //        margin: 0 20px 30px 0;
  //        width: 50%;
  //
  //
  //        &:last-child {
  //          margin: 0 0 30px;
  //        }
  //
  //        .heading,
  //        .single-provider {
  //          font-size: $font-size;
  //        }
  //      }
  //    }
  //  }
  //
  //  .provider-container {
  //    padding: 0 15px;
  //
  //    .provider-info {
  //      .provider-name {
  //        color: $text-color;
  //        display: inline-block;
  //        font-size: 20px;
  //        margin-top: 0;
  //        position: relative;
  //
  //        &::after {
  //          background: $primary-color;
  //          content: '';
  //          height: 3px;
  //          left: 0;
  //          position: absolute;
  //          top: 110%;
  //          width: 100%;
  //        }
  //      }
  //
  //      .logo-container {
  //        padding-top: 15px;
  //
  //        .card-cnt {
  //          align-items: center;
  //          background: $background;
  //          border: 1px solid $border-color;
  //          border-radius: $radius;
  //          display: flex;
  //          height: 52px;
  //          justify-content: center;
  //          margin-bottom: 15px;
  //          padding: 0 7px;
  //
  //          @media #{$mat-lt-sm} {
  //            margin: 0 0 15px;
  //            padding: 8px 5px;
  //          }
  //
  //          &:hover {
  //            border-color: $tertiary;
  //          }
  //
  //          img {
  //            display: block;
  //            height: 100%;
  //            margin: 0 auto;
  //            max-height: 28px;
  //            max-width: 75px;
  //            object-fit: contain;
  //            width: auto;
  //          }
  //
  //          a {
  //            align-items: center;
  //            display: flex;
  //            justify-content: center;
  //            width: 100%;
  //          }
  //        }
  //      }
  //
  //      p {
  //        font-size: 14px;
  //        padding: 10px 0;
  //      }
  //
  //      .details-container {
  //        padding: 15px 0;
  //
  //        h3 {
  //          color: $text-color;
  //          margin: 0;
  //          padding: 10px 0 20px;
  //        }
  //      }
  //    }
  //
  //    .available-amount {
  //      color: $text-color;
  //      margin: 15px 0;
  //
  //      .amount {
  //        color: $tertiary;
  //        margin-left: 5px;
  //      }
  //    }
  //
  //  }
  //}

  .payments-method-form {
    //padding-top: 30px;

    .form-row {
      flex-wrap: nowrap;

      .amount-active {
        background: $secondary;
        color: $text-on-secondary;
      }
    }

    .mat-form-field-prefix {
      margin-right: 5px;
    }

    .mat-form-field-wrapper {
      margin-bottom: 0;
      padding-bottom: 1em;

      .mat-form-field-infix {
        width: 110px * 2;
      }
    }

    .mat-flat-button {
      margin-bottom: 1em;
      width: 115px;
    }

    @media #{$mat-lt-sm} {

      .mat-form-field-wrapper {

        .mat-form-field{
          &-infix {
            width: 190px;
          }
        }
      }

      .mat-flat-button {
        width: 105px;
        font-size: 12px !important;
      }
    }
  }
}
