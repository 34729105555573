@mixin registration-new($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $prefix-shadow: if($is-dark, $background, $text-on-primary);

  registration-new{

    mobile-prefix{
      input:-webkit-autofill{
        box-shadow: 0 0 1000px $prefix-shadow inset;
      }
    }

    input:-webkit-autofill{
      box-shadow: 0 0 1000px $background inset;
      -webkit-text-fill-color: $text-color;
    }
  }
}
