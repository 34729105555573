@mixin wallets-viewer($theme) {
  $is-dark: map-get($theme, is-dark);
  $background: map-get($theme, background);
  $tertiary: map-get($theme, tertiary);
  $font-size: map-get($theme, font-size);
  $text-color: map-get($theme, text-color);
  $success: map-get($theme, success);
  $header-table-background: if($is-dark, lighten($background, 5%), lighten($background, 10%));
  $table-text-color: if($is-dark, #F2F2F2, $text-color);

  .wallets-wrapper {
    .available {
      font-size: $font-size - 1;

      > :first-child {
        color: $tertiary;
        text-transform: none;
      }
    }

    table {
      color: $table-text-color;
      font-size: $font-size - 1;

      tr:first-child {
        background-color: $header-table-background;
        border-top: 1px solid $background;
        text-align: left;
      }
    }

    td:not(:last-child),
    th:not(:last-child) {
      border-right: 1px solid $background;
    }

    tr:not(:last-child) td {
      border-bottom: 1px solid $background;
    }

    .progress-bar-container {
      color: #837F7F;
      font-size: 7px;
    }

    .mat-progress-bar-buffer mat-progress-bar-element {
      border-radius: 10px;
    }

    .mat-progress-bar .mat-progress-bar-fill::after {
      background-color: $success;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }
  }
}
