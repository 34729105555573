@mixin card($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $primary-color: map-get($map: $theme, $key: primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $font-size: map-get($map: $theme, $key: font-size);
  $title-background: if($is-dark, lighten($background, 10%), lighten($background, 100%));
  $sub-card-bg: if($is-dark, lighten($background, 10%), lighten($background, 100%));
  $success: map-get($theme, success);
  $radius: map-get($map: $theme, $key: radius);
  $shadow-small: if($is-dark, 0 2px 2px 1px rgba(#000, .45), 0 2px 2px 1px rgba($text-color, .2));
  $dark-card: if($is-dark, #2D2D2D, $background);
  $filter-btn: if($is-dark, lighten($background, 5%), #F5F5F5);

  .mat-mdc-card {

    &-content {
      padding: 16px;
    }

    &.new-card {
      margin: 5px;

      @media #{$mat-lt-sm} {
        margin: 5px;
      }

      .mat-mdc-card-title {
        @include ellipsis;
        align-items: center;
        background: $title-background;
        border-bottom: 2px solid $tertiary;
        border-radius: $radius $radius 0 0;
        color: $tertiary;
        display: flex;
        font-size: $font-size;
        font-weight: bold;
        height: 40px;
        margin-bottom: 0;
        padding: 0 15px;
        position: relative;
        text-transform: uppercase;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        @media #{$mat-lt-sm} {
          padding: 0 10px;
        }

        &.has-action {
          align-items: center;
          display: flex;
          justify-content: space-between;

          button {
            flex-shrink: 0;
            min-width: 0;
            padding: 5px 12px;

            .mat-button-wrapper {
              align-items: center;
              display: flex;
            }

            // .md-icon {
            //   font-size: $font-size + 3;
            // }
          }

          .sidebar-action {
            color: $text-color;
            padding: 4px;
            position: absolute;
            right: 5px;
            transition: all .5s ease;
            width: 28px;

            md-icon {
              opacity: 1 !important;
            }
          }
        }
      }

      &.dark-card {
        background-color: $dark-card;
      }

      //&.jackpot-card {
      //  .mat-mdc-card-header {
      //    padding: 10px;
      //    position: relative;
      //
      //    .mat-card-header-text {
      //      display: none;
      //    }
      //
      //    .icon {
      //      font-size: $font-size + 5;
      //      inset: 10px 10px auto auto;
      //      opacity: .7;
      //      position: absolute;
      //    }
      //  }
      //
      //  .mat-mdc-card-content {
      //    padding: 10px 16px 16px;
      //
      //    .tournament-title {
      //      @include tablet {
      //        font-size: $font-size + 5;
      //      }
      //    }
      //
      //    .tournament-box {
      //      background: transparent;
      //      display: grid;
      //      gap: 8px;
      //
      //      @include tablet {
      //        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
      //      }
      //
      //      .box {
      //        align-items: center;
      //        background: $background;
      //        border-radius: $radius;
      //        display: flex;
      //        flex-direction: column;
      //        flex-wrap: wrap;
      //        font-size: $font-size - 1;
      //        padding: 8px;
      //
      //        md-icon {
      //          font-size: $font-size + 3;
      //          margin-bottom: 8px;
      //        }
      //
      //        &-title {
      //          margin-bottom: 4px;
      //        }
      //
      //        &-info {
      //          color: $success;
      //        }
      //      }
      //    }
      //  }
      //}

      &.flat-card {
        @media #{$mat-lt-md} {
          border: none;
          border-radius: 0;
          box-shadow: none;

          > .mat-mdc-card-title,
          > .mat-expansion-panel .mat-expansion-panel-header {
            background: $title-background !important;
            border-radius: 0 !important;
            color: $text-color;
          }

          > .mat-mdc-card-title {
            color: $tertiary;
          }
        }
      }

      &.sub-card {
        background: $sub-card-bg;

        .mat-mdc-card-title {
          background: $sub-card-bg;
        }

        .mat-mdc-card-content {
          padding: 15px;
        }
      }
    }

    &.no-padding {
      box-shadow: none !important;
      padding: 0 !important;
    }

    &.tracker-card {

      md-icon,
      .icon {
        &:not(.active) {
          color: $text-color;
          opacity: .5;

          &:hover {
            opacity: 1;
          }
        }

        &.icon-field {
          &::before {
            font-size: $font-size + 4 !important;
          }
        }
      }
    }

    &.fancy-card {
      background: $sub-card-bg;
      box-shadow: $shadow-small;

      .mat-mdc-card-header {

        .mat-mdc-card-header-text {
          margin: 0;
          width: 100%;
        }

        .mat-mdc-card-title {
          border-bottom: 2px solid $tertiary;
          color: $text-color;
          font-size: $font-size + 5;
          height: auto;
          margin: 0;
          padding: 15px;
          transition: padding-left .2s ease-in-out;

        }

        .mat-mdc-card-subtitle {
          color: $tertiary;
          font-weight: bold;
          margin: 0 !important;
          padding: 15px;
        }
      }

      &:hover {
        .mat-mdc-card-header {
          .mat-mdc-card-title {
            padding-left: 25px;

            @media #{$mat-lt-md} {
              padding-left: 15px;
            }
          }
        }
      }
    }

    &.promo-card {
      justify-content: space-between;
      padding: 12px !important;
    }

    &.sport-card {
      .mat-mdc-card-title {
        .card {
          &-icon {
            margin-right: 4px;
          }
        }

        .filter-btn {
          font-size: $font-size;
          padding: 0;
          width: 24px;

          &-active {
            background-color: $filter-btn;
            color: $primary-color;
          }
        }
      }
    }
  }
}
