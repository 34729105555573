@mixin menu($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $font-size: map-get($map: $theme, $key: font-size);
  $border-color: map-get($map: $theme, $key: border-color);
  $sport-item-background: if($is-dark, lighten($background, 2%), lighten($background, 100%));
  $category-node-background: if($is-dark, lighten($background, 10%), darken($sport-item-background, 3%));
  $category-node-hover-background: if($is-dark, lighten($background, 12%), darken($background, 5%));
  $active-color: map-get($map: $theme, $key: tertiary);
  $text-color: map-get($map: $theme, $key: text-color);

  menu,
  brand-header-selector {
    .nav-item {

      > div {
        // border-bottom: 1px solid $border-color;
      }

      .nav-link {
        align-items: center;
        background: rgba($category-node-background, .3);
        color: $text-color;
        display: flex;
        font-size: $font-size;
        opacity: .75;
        padding: 10px;

        &.active {
          opacity: 1;
        }

        &:hover:not(.active) a {
          color: $text-color;
        }

        &:hover {
          background-color: $category-node-hover-background;
        }
      }
    }

    &.inline {

      ul {
        display: flex;
        list-style: none;
      }

      li {

        > div {
          border-bottom: none;
        }
      }

      .nav-link {
        background: none;
      }
    }

    &.mobile {

      ul {
        overflow-x: auto;

        .nav-link {
          padding: 0 10px;
        }

        li {
          a {
            font-size: $font-size;
            font-weight: 700;
            height: 40px;
            line-height: 1;
            margin: 0;
            text-transform: uppercase;
            white-space: nowrap;

            &.nav-link.active {
              background-color: $category-node-hover-background;
              color: $active-color;
            }
          }
        }
      }
    }

    &.floating {
      margin: -8px 0;

      ul > li > ul {
        display: none;
      }
    }

    &.menu-container {
      &:not(.mobile) {
        ul {
          li {
            a {
              margin: 0 10px;
              padding-bottom: 10px;
            }
          }
        }
      }

      ul {
        justify-content: center;

        .nav-item {
          &.separator::before {
            border-left: 1px solid rgba($text-color, .5);
            bottom: 24px;
            content: '';
            height: 20px;
            margin-left: -40px;
            position: absolute;
          }

          a {
            border-bottom: 3px solid $background;
            color: rgba($text-color, .5);
            font-weight: 700;
            line-height: 43px;
            padding: 0 10px;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;

            md-icon {
              display: none;
            }

            &.active {
              border-bottom-color: $active-color;
              color: $active-color;
              font-weight: 700;
            }

            &:hover {
              background-color: transparent;

              &:not(.active) {
                color: $text-color;
              }
            }
          }
        }
      }
    }
  }
}
