@mixin search-events-dropdown($theme) {

  .search-events-dropdown-container {
    padding: 3px 5px 0;

    .max-h {
      max-height: 200px;
    }
  }
}
