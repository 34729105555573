@mixin sharer($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $primary-color: map-get($map: $theme, $key: primary);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $copy-btn: if($is-dark, lighten($background, 10%), lighten($background, 100%));

  sharer {
    form {
      margin: 0 45px 45px;

      @media #{$mat-lt-sm} {
        margin: 0 5px 5px;
      }
    }

    .wrapper {
      position: relative;

      mat-error {
        left: 0;
        position: absolute;
        right: 0;

        @media #{$mat-lt-sm} {
          position: unset;
        }
      }
    }

    .mat-tab-group {
      margin: 10px 0;

      .mat-tab-header {
        border-bottom: none !important;
      }

      .mat-tab-label {
        background: $copy-btn;
        border-radius: 3px;
        color: $text-color;
        text-transform: uppercase;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &.mat-tab-label-active {
          background: $primary-color;
          color: $text-on-primary;
        }
      }

      .mat-tab-body-wrapper {
        margin-top: 15px;
      }

      .mat-ink-bar {
        display: none;
      }
    }
  }
}
