@mixin report-cashier($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $background: map-get($map: $theme, $key: background);
  $summary-background: if($is-dark, lighten($background, 6%), $text-on-primary);
  $card-title-color: if($is-dark, $text-on-primary, #000);
  $th-background: if($is-dark, lighten($background, 4%), #F9F9F9);
  $td-background: if($is-dark, lighten($background, 6%), $text-on-primary);

  .report-cashier-wrapper {
    @include tablet {
      tr:not(.tablet-only) {
        display: table-row;
      }

      td {
        display: table-cell;
      }
    }

    .movements-summary {
      background: $summary-background;
      border: 1px solid $background;
      border-radius: 2px;

      &-title {
        color: $tertiary;
      }
    }

    .mat-card mat-card-title.mat-card-title {
      background: $background;
      border-bottom: 1px solid $tertiary;
      color: $card-title-color;
    }

    th {
      background: $th-background;
      color: $tertiary;
    }

    td {
      background: $td-background;
    }

    .mobile-only {
      color: $tertiary;
    }
  }
}
