@mixin footer($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $footer-background: map-get($map: $theme, $key: footer);
  $text-on-footer: map-get($map: $theme, $key: text-on-footer);
  $footer-img-dir: '/assets/images';
  $payments-img-dir: '/assets/images/payments';
  $go-up-background: if($is-dark, lighten($footer-background, 15%), lighten($footer-background, 3%));
  $item-active-color: map-get($map: $theme, $key: tertiary);
  $font-size: map-get($map: $theme, $key: font-size);
  $tertiary: map-get($map: $theme, $key: tertiary);

  .footer-container {
    background: $footer-background;
    color: $text-on-footer;
    line-height: 1.5;

    &.mobile {
      margin-bottom: 80px;

      .disclaimer-container {
        max-width: 90%;
      }
    }

    a {
      color: rgba($text-on-footer, .75);
    }

    h3 {
      text-transform: uppercase;
    }

    &:not(.mobile) {
      .disclaimer-container {
        max-width: 40%;

        span {
          display: block;
          text-align: justify;
        }
      }
    }

    .social-icons {
      text-align: center;

      a {
        display: inline-block;
        height: 30px;
        margin: 0 15px;
        width: 30px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .license-area {
      font-size: $font-size - 1px;
      text-align: center;

      .license-img {
        display: block;
        height: 60px;
        margin-bottom: 10px;
        overflow: hidden;
      }

      .img-fluid {
        max-height: 24px;
      }

      .plus-18 {
        color: white;
        background-color: #F44336;
        border-radius: 50%;
        font-family: sans-serif;
        font-size: $font-size - 4;
        font-weight: bold;
        height: 20px;
        line-height: 1;
        padding: 6px 2px;
        width: 20px;
      }


      span {
        color: rgba($text-on-footer, .5);
      }
    }

    .licensee-logo-wrapper {
      background-position: center center;
      background-repeat: no-repeat;
      height: 30px;
      text-align: center;
      width: 100%;
    }

    .logo-container {
      padding: 15px 0;
      width: 200px;

      .bck {
        background-position: center;
        background-repeat: no-repeat;
        height: 35px;
      }
    }

    ul {
      list-style: none;

      li {
        padding: 3px 0;

        &:hover {
          a {
            color: $item-active-color;
          }
        }
      }
    }

    .links-container {
      justify-content: space-evenly;

      svg{
        display: none;
      }
    }

    .disclaimer-container {

      span {
        color: rgba($text-on-footer, .75);
        line-height: 1.5;

        a {
          color: $tertiary;
        }
      }
    }

    .go-up-container {
      align-items: center;
      background: $go-up-background;
      border-radius: 0 0 20px 20px;
      display: flex;
      height: 45px;
      justify-content: center;
      width: 45px;
    }

    .socials-container {

      .logo-social {
        height: 25px !important;
        opacity: .7;
        transition: all .3s ease;

        .logo-wrapper {
          flex: 0 0 42px !important;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .socials-container,
    .payments-container,
    .operators-container {
      margin-top: 10px;

      .logo-wrapper {
        display: flex;
        flex: 0 0 65px;
        justify-content: center;
        margin-bottom: 15px;

        @media (max-width: 400px) {
          flex: 0 0 55px;
        }
      }
    }
  }

  .payments-container {
    .logo-wrapper {
      background-color: #E2E2E2;
      border-radius: 5px;
      margin: 4px;
      padding: 3px 12px;
    }
  }

  .logo-gambleAware {
    $img-url: if($is-dark, '#{$footer-img-dir}/footer/static-icons/gambleaware-dark.png', '#{$footer-img-dir}/footer/static-icons/gambleaware-light.png');
    background-image: url($img-url);
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px;
    width: 100px;
  }

  .logo-wrapper {
    .bs-logo {
      $img-url: if($is-dark, '#{$footer-img-dir}/footer/static-icons/bs-light.svg', '#{$footer-img-dir}/footer/static-icons/bs-dark.svg');
      background-image: url($img-url);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 25px;
      width: 25px;
    }

    span {
      color: rgba($text-on-footer, .5);
      font-size: $font-size - 1px;
    }
  }
}
