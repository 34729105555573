@mixin sidebar-column($theme) {
  #widget-stats {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    &.tain {
      padding-top: 0;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
