@mixin sidebar-banner($theme) {
  .sidebar-banner-container {
    .slider-container {
      height: 290px;

      .slide-content-container {
        justify-content: flex-end !important;
        padding-bottom: 20px;

        .button-container{
          align-items: center !important;
        }
      }

      .swiper-pagination {
        inset: 10px 10px auto auto;
        text-align: right;
      }
    }
  }
}
